import React from "react";
import { Checkbox, FormControlLabel, makeStyles, Paper, TablePagination } from "@material-ui/core";
import { useSnackbar } from "notistack";

import AdminService from "../../Services/AdminService.js";
import ApiKeysList from "../../Components/ApiKeys/ApiKeysList";

const useStyles = makeStyles(theme => ({
  filter: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

export default function ApiKeysListContainer() {
  const [apiKeys, setApiKeys] = React.useState(null);
  const [total, setTotal] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [showDeleted, setShowDeleted] = React.useState(false);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const fetchApiKeys = React.useCallback(
    (_page, _showDeleted) => {
      AdminService.getApiKeys(_page, _showDeleted)
        .then(res => {
          setTotal(res.data.apikeysCount);
          setApiKeys(res.data.apikeys);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération des clés API", { variant: "error" });
        });
    },
    [enqueueSnackbar],
  );

  React.useEffect(() => {
    fetchApiKeys(page, showDeleted);
  }, [fetchApiKeys, page, showDeleted]);

  const deleteApiKey = apiKey => {
    AdminService.deleteApiKey(apiKey.key)
      .then(() => {
        fetchApiKeys();
        enqueueSnackbar("La clé API a été supprimée", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression de la clé API", { variant: "success" });
      });
  };

  const onPageChange = (event, _page) => {
    setPage(_page);
  };

  const toggleShowDeleted = () => setShowDeleted(!showDeleted);

  const Pagination = () =>
    apiKeys?.length > 0 ? (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={50}
        rowsPerPageOptions={[50]}
        page={page}
        backIconButtonProps={{
          "aria-label": "Page précédente",
        }}
        nextIconButtonProps={{
          "aria-label": "Page suivante",
        }}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
      />
    ) : null;

  return (
    <Paper>
      <div className={classes.filter}>
        <FormControlLabel
          control={<Checkbox checked={showDeleted} onChange={toggleShowDeleted} name="deleted" color="primary" />}
          label="Montrer les clés API supprimées"
        />
      </div>
      <Pagination />
      <ApiKeysList apiKeys={apiKeys} onDeleteItem={deleteApiKey} />
      <Pagination />
    </Paper>
  );
}
