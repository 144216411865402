import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Alert from "@material-ui/lab/Alert";

import ContractService from "../../Services/ContractService";
import ContractSignatory from "../../Components/Signature/ContractSignatory";
import PDFViewer from "../../Components/PDFViewer";

const useStyles = makeStyles(theme => ({
  logo: { marginBottom: theme.spacing() },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  centerz: {
    textAlign: "center",
  },
  iframe: { width: "100%", height: 400, marginTop: theme.spacing(2.5) },
  submitbtn: {
    marginBottom: theme.spacing(2),
  },
  loader: {
    height: 300,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& > p": {
      marginTop: theme.spacing(2),
    },
  },
}));

function ClientSignaturePage() {
  const [signatories, setSignatories] = useState(null);
  const [fileSource, setFileSource] = useState(null);
  const [contractSigned, setContractSigned] = useState(false);
  const classes = useStyles();
  const { contractId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const fetchSignatories = useCallback(() => {
    setSignatories(null);
    setFileSource(null);
    ContractService.getContractPdf(contractId)
      .then(res => {
        const _blob = new Blob([res.data], { type: "application/pdf" });
        const downloadHrefF = window.URL.createObjectURL(_blob);
        setFileSource(downloadHrefF);
      })
      .catch(e => {
        console.log(" went wrong", e);
        enqueueSnackbar("Une erreur est survenue lors de la récupération du contrat", { variant: "error" });
      });
    ContractService.getSignatures(contractId)
      .then(res => {
        setSignatories(res.data.signatories);
        setContractSigned(false);
      })
      .catch(e => {
        if (e?.response?.status === 410) {
          setContractSigned(true);
        } else {
          console.log(" FAILED ", e);
          enqueueSnackbar("Une erreur est survenue lors de la récupération de la signature", { variant: "error" });
        }
      });
  }, [contractId, enqueueSnackbar]);

  useEffect(() => {
    fetchSignatories();
  }, [fetchSignatories]);

  const isReady = useMemo(() => signatories && fileSource, [signatories, fileSource]);

  const onSign = () => {
    fetchSignatories();
  };

  return (
    <Grid container>
      <Grid container>
        <Grid item md={1} lg={2} xl={3} />
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Paper className={`${classes.paper} ${classes.centerz}`}>
            <Box>
              <img src={"/groupe_kapital_logo.png"} alt="Groupe Kapital logo" height={50} className={classes.logo} />
            </Box>
            <Typography variant="h4" align="center">
              Signature d'un contrat
            </Typography>
            {/* <iframe type="application/pdf" src={fileSource} className={classes.iframe} title="Preview" /> */}
            {isReady ? (
              <PDFViewer pdfPath={fileSource} />
            ) : contractSigned ? (
              <div style={{ marginTop: 24 }}>
                <Paper>
                  <Alert severity="success">Ce contrat a été signé par le client</Alert>
                </Paper>
              </div>
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
      {isReady && (
        <Grid container>
          <Grid item md={1} lg={2} xl={3} />
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Paper className={`${classes.paper} ${classes.centerz}`}>
              {contractSigned ? (
                <Typography>Le contrat a été signé par le client</Typography>
              ) : (
                signatories.map(signatory => (
                  <ContractSignatory signatory={{ ...signatory }} key={signatory.id} onSign={onSign} />
                ))
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ClientSignaturePage;
