import { Divider, makeStyles, MenuItem, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import DropdownButton from "../Buttons/DropdownButton";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../Dialogs/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function ContractTemplatesListItem({ template, onDelete, onClick, canDelete = false, onCopy, onPreview }) {
  const [isSelected, setIsSelected] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick(template);
    }
  };

  return (
    <TableRow selected={isSelected} className={classes.row} onClick={handleClick}>
      <TableCell component="th" scope="row">
        <Typography variant="h6">{template.name}</Typography>
      </TableCell>
      <TableCell>
        <DropdownButton onOpen={() => setIsSelected(true)} onClose={() => setIsSelected(false)}>
          <CopyToClipboard text={template.name} onCopy={onCopy}>
            <MenuItem>Copier l'identifiant</MenuItem>
          </CopyToClipboard>
          <MenuItem onClick={() => onPreview(template)}>Générer un contrat</MenuItem>
          <Divider />
          {canDelete && (
            <ConfirmationDialog>
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e =>
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => onDelete(template)),
                    )
                  }
                >
                  Supprimer
                </MenuItem>
              )}
            </ConfirmationDialog>
          )}
        </DropdownButton>
      </TableCell>
    </TableRow>
  );
}

export default React.memo(ContractTemplatesListItem);
