import SALogsPage from "../Pages/Superadmin/SALogsPage";
import SAHomePage from "../Pages/Superadmin/SAHomePage";
import SAUsersPage from "../Pages/Superadmin/SAUsersPage";
import SAApiKeysPage from "../Pages/Superadmin/SAApiKeysPage";
import SACreateApiKeysPage from "../Pages/Superadmin/SACreateApiKeysPage";
import SACreateUsersPage from "../Pages/Superadmin/SACreateUsersPage";
import ContractTemplatePage from "../Pages/Connected/ContractTemplatePage";
import DemoPage from "../Pages/Common/DemoPage";

const superAdminRoutes = [
  {
    path: "/templates/nouveau",
    name: "Création d'un template",
    Component: ContractTemplatePage,
    key: "CREATE_TEMPLATE",
  },
  { path: "/superbg", name: "Accueil", Component: SAHomePage, key: "HOME" },
  { path: "/superbg/logs", name: "Logs", Component: SALogsPage, key: "LOGS" },
  { path: "/superbg/utilisateurs", name: "Utilisateurs", Component: SAUsersPage, key: "USERS" },
  {
    path: "/superbg/utilisateurs/nouveau",
    name: "Nouvel utilisateur",
    Component: SACreateUsersPage,
    key: "CREATE_USERS",
  },
  { path: "/superbg/cles-api", name: "Clés API", Component: SAApiKeysPage, key: "APIKEYS" },
  {
    path: "/superbg/cles-api/nouvelle",
    name: "Nouvelle clé API",
    Component: SACreateApiKeysPage,
    key: "CREATE_APIKEY",
  },
  { path: "/demo", name: "Demo", Component: DemoPage, key: "DEMO", hideBreadcrumbs: true },
];

export default superAdminRoutes;
