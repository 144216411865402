import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import LogsViewer from "../../Components/Logs/LogsViewer";

import UserStore from "../../Stores/UserStore";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
}));

function SALogsPage() {
  const user = useContext(UserStore);

  const classes = useStyles();

  if (user?.profile?.isSuperAdmin) {
    return (
      <>
        <PageTitleTPSource>Logs</PageTitleTPSource>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <LogsViewer />
          </Grid>
        </Grid>
      </>
    );
  }
  return <Redirect to="/" />;
}

export default observer(SALogsPage);
