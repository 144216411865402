import React, { useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import ContractService from "../../Services/ContractService";

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    opacity: "1 !important",
  },
  middle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  otpbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "& > *": {
      width: 250,
      marginTop: theme.spacing(2),
      "&:first-child": {
        marginTop: 0,
      },
    },
  },
}));

export default function ContractSignatory({ signatory, onSign }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const classes = useStyles();
  const { contractId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleOtpCodeChange = useCallback(e => {
    setOtpCode(e.target.value);
  }, []);

  const handleChallengeOtp = useCallback(
    otp => {
      setIsProcessing(true);
      ContractService.challengeOtp(contractId, signatory.id, otp)
        .then(res => {
          setIsSigning(false);
          setIsProcessing(false);
          enqueueSnackbar(`${signatory.firstname} ${signatory.lastname} vient de signer le contrat`, {
            variant: "success",
          });
          onSign();
        })
        .catch(e => {
          console.log(" FAILED ", e);
          enqueueSnackbar("Une erreur est survenue lors de la vérification du code", {
            variant: "error",
          });
          setIsSigning(false);
          setIsProcessing(false);
        });
    },
    [onSign, enqueueSnackbar, signatory, contractId],
  );

  const handleSendOtp = () => {
    setIsProcessing(true);
    ContractService.sendOtpToSignatory(contractId, signatory.id)
      .then(() => {
        enqueueSnackbar("Un code a été envoyé");
        setIsSigning(true);
      })
      .catch(e => {
        console.log(" FAILED ", e);
        enqueueSnackbar("Une erreur est survenue lors de l'envoi du code", { variant: "error" });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <Accordion>
      <AccordionSummary
        disabled={signatory.hasSigned}
        expandIcon={!signatory.hasSigned && <ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        className={classes.content}
      >
        <Box className={classes.row}>
          <Checkbox checked={signatory.hasSigned} color="primary" />
          <Typography>
            {signatory.firstname} {signatory.lastname}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {isSigning ? (
          <Box className={classes.otpbox}>
            <TextField
              fullWidth
              id="outlined-title"
              label="Code reçu par SMS"
              variant="outlined"
              value={otpCode}
              onChange={handleOtpCodeChange}
              required
              autoComplete="off"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleChallengeOtp(otpCode)}
              disabled={isProcessing || !otpCode}
            >
              Signer
            </Button>
            <Button variant="outlined" color="primary" onClick={handleSendOtp} disabled={isProcessing}>
              Recevoir un nouveau code
            </Button>
          </Box>
        ) : (
          <Box className={classes.middle}>
            <Button variant="contained" color="primary" onClick={handleSendOtp} disabled={isProcessing}>
              Envoyer un code
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
