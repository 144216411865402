import { useState } from "react";
import Box from "@material-ui/core/Box";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button, CircularProgress, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function PDFViewer({ pdfPath }) {
  const [countPages, setCountPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentScale, setCurrentScale] = useState(1.0);
  const [value, setValue] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setCountPages(numPages);
  };

  const handleInputChange = e => {
    const { value } = e.target;
    if (value === "") {
      setCurrentPage("");
      return;
    }
    const parsed = parseInt(value, 10);
    if (parsed < 0) {
      setCurrentPage(1);
    } else if (parsed > countPages) {
      setCurrentPage(countPages);
    } else {
      setCurrentPage(parsed);
    }
  };

  const handleScaleChange = (event, newScale) => {
    setCurrentScale(parseFloat(newScale));
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        style={{ marginBottom: 16 }}
      >
        <Tab label="Versoin classique" />
        <Tab label="Version beta" />
      </Tabs>
      {value === 1 && (
        <Box>
          <Box
            className="all-page-container"
            style={{
              backgroundColor: "#F7F7F7",
              border: "1px solid #F7F7F7",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Document
              file={pdfPath}
              options={{
                workerSrc: "/pdf.worker.js",
                standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
                cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                cMapPacked: true,
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              style={{ height: 300 }}
              error="Une erreur est survenue lors du chargement du PDF"
              renderMode="svg"
              loading={
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 300,
                    height: 300,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                  <Typography style={{ marginTop: 8 }} variant="body2">
                    Chargement du document
                  </Typography>
                </Box>
              }
            >
              {Array.from(new Array(countPages), (el, index) => (
                <Box key={`page_${index + 1}`}>
                  <Page
                    pageNumber={index + 1}
                    loading="Chargement de la page..."
                    inputRef={currentPage === index + 1 ? ref => ref && ref.scrollIntoView() : null}
                    scale={currentScale}
                    renderMode="canvas"
                  />
                  <Typography variant="body2" style={{ marginBottom: 24, marginTop: 8 }}>
                    Page {index + 1} / {countPages}
                  </Typography>
                </Box>
              ))}
            </Document>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" style={{ marginRight: 16 }}>
                Aller à la page :
              </Typography>
              <TextField
                inputProps={{
                  min: 1,
                  max: countPages,
                  style: { textAlign: "center" },
                }}
                type="number"
                style={{ width: 100, textAlign: "right" }}
                size="small"
                onChange={handleInputChange}
                value={currentPage}
              />
            </Box>
            <Box style={{ marginTop: 16 }}>
              <ToggleButtonGroup
                value={currentScale}
                exclusive
                onChange={handleScaleChange}
                aria-label="text alignment"
              >
                <ToggleButton value={1} style={{ width: 60 }}>
                  x1
                </ToggleButton>
                <ToggleButton value={1.25} style={{ width: 60 }}>
                  x1.25
                </ToggleButton>
                <ToggleButton value={1.5} style={{ width: 60 }}>
                  x1.5
                </ToggleButton>
                <ToggleButton value={2} style={{ width: 60 }}>
                  x2
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box style={{ marginTop: 16 }}>
              <a href={pdfPath} download style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="primary">
                  Télécharger
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
      )}
      {value === 0 && (
        <iframe
          src={pdfPath}
          style={{
            width: "100%",
            height: 500,
          }}
          title="Preview"
        />
      )}
    </>
  );
}
