import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
  Tooltip,
  OutlinedInput,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";

import ContractTemplateStore from "../../Stores/ContractTemplateStore";
import { blockTypes } from "../../Utils/contract";
import ContentBlockEditor from "./ContentBlockEditor";

function ContractTemplateBlockEditDialog({ selectedIndex, onClose }) {
  const contractTemplate = React.useContext(ContractTemplateStore);
  const [blockTitle, setBlockTitle] = React.useState();
  const [blockType, setBlockType] = React.useState();
  const [blockContent, setBlockContent] = React.useState();
  const [blockContentCurrent, setBlockContentCurrent] = React.useState();
  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    if (selectedIndex !== null) {
      const data = contractTemplate.getBlock(selectedIndex);
      if (data) {
        const { type, title, content } = data;
        setBlockTitle(title);
        setBlockType(type);
        setBlockContent(content);
        setBlockContentCurrent(content);
        setShowDialog(true);
      }
    } else {
      // Hide dialog before data reset
      setShowDialog(false);
      setBlockTitle(null);
      setBlockType(null);
      setBlockContentCurrent(null);
      setBlockContent(null);
    }
  }, [selectedIndex]); // eslint-disable-line

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    const data = {
      title: blockTitle,
      type: blockType,
      content: ["CONTENT_BLOCK", "CONTENT_BLOCK_CENTER"].includes(blockType) ? blockContentCurrent : "",
    };
    contractTemplate.updateBlock(selectedIndex, data);
    handleClose();
  };

  const handleBlockTypeChange = e => {
    setBlockType(e.target.value);
  };

  const handleBlockTitleChange = e => {
    setBlockTitle(e.target.value);
  };

  const handleBlockContentChange = (state, actions) => {
    const asHTML = actions.toHTML(state);
    setBlockContentCurrent(asHTML);
  };

  if (!showDialog) {
    return null;
  }

  return (
    <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">Edition d'un bloc {blockTitle && `- ${blockTitle}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outline-title">Titre du bloc</InputLabel>
              <OutlinedInput
                id="outline-title"
                label="Titre du bloc"
                type="text"
                fullWidth
                value={blockTitle}
                onChange={handleBlockTitleChange}
                endAdornment={
                  <InputAdornment position="end" style={{ cursor: "pointer" }}>
                    <Tooltip title="Champ facultatif permettant de retrouver votre bloc plus facilement">
                      <InfoIcon style={{ color: "#C3C3C3" }} />
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-blockType">Type de bloc</InputLabel>
              <Select
                value={blockType}
                onChange={handleBlockTypeChange}
                label="Type de bloc"
                inputProps={{
                  name: "blockType",
                  id: "outlined-blockType",
                }}
              >
                {Object.keys(blockTypes).map(type => (
                  <MenuItem value={blockTypes[type].key} key={blockTypes[type].key}>
                    {blockTypes[type].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {["CONTENT_BLOCK", "CONTENT_BLOCK_CENTER"].includes(blockType) && (
              <ContentBlockEditor content={blockContent} blockType={blockType} syncContent={handleBlockContentChange} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(ContractTemplateBlockEditDialog);
