import React from "react";
import { Drawer, IconButton, List, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DescriptionIcon from "@material-ui/icons/Description";
import GavelIcon from "@material-ui/icons/Gavel";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import LockIcon from "@material-ui/icons/Lock";

import ConfirmationDialog, { stopPropagationForConfirmation } from "../Dialogs/ConfirmationDialog";
import UserStore from "../../Stores/UserStore";
import MenuItem from "./MenuItem";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  drawerOpenWBG: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#000",
    color: "#F7F7F7",
  },
  drawerCloseWBG: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    backgroundColor: "#000",
    color: "#F7F7F7",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const MENU = [
  { label: "Suivi dossiers", path: "/", icon: DescriptionIcon },
  { label: "Contrats", path: "/templates", icon: GavelIcon },
];

function Menu({ open, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const user = React.useContext(UserStore);

  const handleRedirect = path => {
    history.push(path);
  };

  const isActive = React.useCallback(
    entryPath => {
      if (entryPath === "/") {
        return pathname === "/";
      }
      return pathname.startsWith(entryPath);
    },
    [pathname],
  );

  return (
    <Drawer
      color="secondary"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpenWBG]: open,
          [classes.drawerCloseWBG]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon style={{ color: "#F7F7F7" }} />
        </IconButton>
      </div>
      <List>
        {user?.profile?.isSuperAdmin && (
          <MenuItem
            onClick={() => handleRedirect("/superbg")}
            label="Administration"
            Icon={LockIcon}
            isActive={isActive("/superbg")}
          />
        )}
        {MENU.map((entry, key) => (
          <MenuItem
            key={`${key}-menu-item`}
            onClick={() => handleRedirect(entry.path)}
            label={entry.label}
            Icon={entry.icon}
            isActive={isActive(entry.path)}
          />
        ))}
        <ConfirmationDialog situationText="Êtes-vous sûr de vouloir vous déconnecter ?">
          {confirmBeforeAct => (
            <MenuItem
              onClick={e =>
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => {
                    user.signOut();
                  }),
                )
              }
              label="Déconnexion"
              Icon={ExitToAppIcon}
            />
          )}
        </ConfirmationDialog>
      </List>
    </Drawer>
  );
}

export default observer(Menu);
