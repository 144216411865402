import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  buttons: { marginTop: theme.spacing() },
  cancel: {
    marginRight: theme.spacing(),
  },
}));

function UserForm({ onSubmit, postSubmit }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const classes = useStyles();

  const _onSubmit = async values => {
    setIsSubmitting(true);
    const submitted = await onSubmit(values);
    setIsSubmitting(false);
    if (submitted) {
      postSubmit(submitted);
    }
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="firstname"
            control={control}
            rules={{ required: { value: true, message: "Champ obligatoire" } }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Prénom *"
                placeholder="Prénom"
                variant="outlined"
                error={Boolean(errors?.firstname)}
                helperText={errors?.firstname?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lastname"
            control={control}
            rules={{ required: { value: true, message: "Champ obligatoire" } }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Nom *"
                placeholder="Nom"
                variant="outlined"
                error={Boolean(errors?.lastname)}
                helperText={errors?.lastname?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phone"
            control={control}
            rules={{ required: { value: true, message: "Champ obligatoire" } }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Téléphone *"
                placeholder="Téléphone"
                variant="outlined"
                error={Boolean(errors?.phone)}
                helperText={errors?.phone?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            rules={{ required: { value: true, message: "Champ obligatoire" } }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email *"
                placeholder="Email"
                variant="outlined"
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} align="right" className={classes.buttons}>
          <Button component={Link} to="/superbg/utilisateurs" className={classes.cancel} disabled={isSubmitting}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            Ajouter
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default UserForm;
