import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles(theme => ({
  buttons: { marginTop: theme.spacing() },
  cancel: {
    marginRight: theme.spacing(),
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Ce champ est obligatoire").email("Format invalide"),
  password: Yup.string().required("Ce champ est obligatoire"),
});

function LoginForm({ onSubmit, postSubmit }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const classes = useStyles();

  const _onSubmit = async values => {
    setIsSubmitting(true);
    const submitted = await onSubmit(values);
    setIsSubmitting(false);
    if (submitted && postSubmit) {
      postSubmit(submitted);
    }
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            {...register("email")}
            variant="outlined"
            fullWidth
            label="Email"
            placeholder="Email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            {...register("password")}
            variant="outlined"
            fullWidth
            label="Mot de passe"
            placeholder="Mot de passe"
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
            type="password"
          />
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
            Connexion
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginForm;
