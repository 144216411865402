import ClientSignaturePage from "../Pages/Common/ClientSignaturePage";
import ConfirmContractPage from "../Pages/Common/ConfirmContractPage";
import ContractsPage from "../Pages/Connected/ContractsPage";
import ContractTemplatePage from "../Pages/Connected/ContractTemplatePage";
import ContractTemplatesPage from "../Pages/Connected/ContractTemplatesPage";
import ContractValidationPage from "../Pages/Connected/ContractValidationPage";
import ProfilePage from "../Pages/Connected/ProfilePage";

const routes = [
  { path: "/", name: "Accueil", Component: ContractsPage, key: "HOME" },
  { path: "/profil", name: "Profil", Component: ProfilePage, key: "PROFILE", hideBreadcrumbs: true },
  {
    path: "/preview/:contractId",
    name: "Confirmation d'un contrat",
    Component: ConfirmContractPage,
    key: "CONFIRM_CONTRACT",
  },
  {
    path: "/signature/:contractId",
    name: "Signature d'un contrat",
    Component: ClientSignaturePage,
    key: "SIGN_CONTRACT",
  },
  {
    path: "/validation/:contractId",
    name: "Signature d'un contrat",
    Component: ContractValidationPage,
    key: "SIGN_CONTRACT",
  },
  { path: "/templates", name: "Templates", Component: ContractTemplatesPage, key: "TEMPLATES" },
  {
    path: "/templates/modification/:contractTemplateId",
    name: "Modification d'un template",
    Component: ContractTemplatePage,
    key: "EDIT_TEMPLATE",
  },
];

export default routes;
