import { RichUtils } from "draft-js";
import clearFormatting from "draft-js-clear-formatting";

import variables from "./variables";

export const importConfig = {
  textToEntity: (text, createEntity) => {
    const result = [];
    if (text.match(/{{\s*[\w.]+\s*}}/g) && variables.find(item => item.code === text)) {
      const variableMatch = variables.find(item => item.code === text);
      const enKey = createEntity("{{mention", "IMMUTABLE", {
        mention: { ...variableMatch },
      });
      result.push({
        entity: enKey,
        offset: 0,
        length: text.length,
        result: variableMatch.name,
      });
    }
    return result;
  },
};

export const exportConfig = {
  entityToHTML: (entity, originalText) => {
    if (entity.type === "{{mention") {
      return <span className="variable">{entity.data.mention.code}</span>;
    }
    return originalText;
  },
};

export const toggleBold = setEditorState => e => {
  e.preventDefault();
  setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, "BOLD"));
};

export const toggleItalic = setEditorState => e => {
  e.preventDefault();
  setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, "ITALIC"));
};

export const toggleUnderline = setEditorState => e => {
  e.preventDefault();
  setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
};

export const toggleCenter = setEditorState => e => {
  e.preventDefault();
  setEditorState(editorState => RichUtils.toggleBlockType(editorState, "align-center"));
};

export const toggleLeft = setEditorState => e => {
  e.preventDefault();
  setEditorState(editorState => RichUtils.toggleBlockType(editorState, "align-left"));
};

export const toggleRight = setEditorState => e => {
  e.preventDefault();
  setEditorState(editorState => RichUtils.toggleBlockType(editorState, "align-right"));
};

export const toggleBlockType =
  (setEditorState, blockType = "unstyled") =>
  e => {
    e.preventDefault();
    setEditorState(editorState => RichUtils.toggleBlockType(editorState, blockType));
  };

export const clearFormat = setEditorState => e => {
  setEditorState(editorState => clearFormatting(editorState, {}));
};

export function getSelectedBlocksMap(editorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

export function getSelectedBlocksList(editorState) {
  return getSelectedBlocksMap(editorState).toList();
}

export function getSelectedBlock(editorState) {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

export function getSelectionEntity(editorState) {
  let entity;
  const selection = editorState.getSelection();
  let start = selection.getStartOffset();
  let end = selection.getEndOffset();
  if (start === end && start === 0) {
    end = 1;
  } else if (start === end) {
    start -= 1;
  }
  const block = getSelectedBlock(editorState);

  for (let i = start; i < end; i += 1) {
    const currentEntity = block.getEntityAt(i);
    if (!currentEntity) {
      entity = undefined;
      break;
    }
    if (i === start) {
      entity = currentEntity;
    } else if (entity !== currentEntity) {
      entity = undefined;
      break;
    }
  }
  return entity;
}

export const getEntityRange = (editorState, entityKey) => {
  const block = getSelectedBlock(editorState);
  let entityRange;
  block.findEntityRanges(
    value => value.get("entity") === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get("text").slice(start, end),
        key: block.getKey(),
      };
    },
  );
  return entityRange;
};

export const defaultSuggestionsFilter = (searchValue, suggestions) => {
  const value = searchValue.toLowerCase();
  const triggerSuggestions = suggestions;
  const filteredSuggestions = triggerSuggestions.filter(
    suggestion => !value || suggestion.name.toLowerCase().indexOf(value) > -1,
  );
  const length = filteredSuggestions.length < 20 ? filteredSuggestions.length : 20;
  return filteredSuggestions.slice(0, length);
};
