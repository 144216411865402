import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import UserStore from "../../Stores/UserStore";
import AdminService from "../../Services/AdminService";
import UserForm from "../../Forms/UserForm";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
}));

function SAUsersPage() {
  const user = useContext(UserStore);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleSubmit = async values => {
    const res = await AdminService.createUser({ ...values })
      .then(_res => {
        enqueueSnackbar("Utilisateur ajouté avec succès", { variant: "success" });
        return _res;
      })
      .catch(e => {
        if (e?.response?.status === 409) {
          enqueueSnackbar("Un utilisateur avec cet email existe déjà", { variant: "warning" });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la création de l'utilisateur", { variant: "error" });
        }
        return false;
      });
    return res;
  };

  const handleRedirection = () => {
    history.push("/superbg/utilisateurs");
  };

  if (user?.profile?.isSuperAdmin) {
    return (
      <>
        <PageTitleTPSource>Ajout d'un utilisateur</PageTitleTPSource>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <UserForm onSubmit={handleSubmit} postSubmit={handleRedirection} />
          </Grid>
        </Grid>
      </>
    );
  }
  return <Redirect to="/" />;
}

export default observer(SAUsersPage);
