import { Box, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";

import ContractService from "../../Services/ContractService";

const useStyles = makeStyles(theme => ({
  submitText: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    "& > *": {
      width: 220,
      marginTop: theme.spacing(2),
      "&:first-child": {
        marginTop: 0,
      },
    },
  },
}));

export default function AdminSignature({ contract, onSign }) {
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSendOtp = useCallback(() => {
    setIsProcessing(true);
    ContractService.sendOtpToSignatoryAdmin(contract.id)
      .then(() => {
        setOtpSent(true);
        enqueueSnackbar("Un code vous a été envoyé");
      })
      .catch(e => {
        console.log(" STH WENT WRONG ", e);
        enqueueSnackbar("Une erreur est survenue lors de l'envoi du code", { variant: "error" });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [contract, enqueueSnackbar]);

  const handleChallengeOtp = useCallback(() => {
    setIsProcessing(true);
    ContractService.challengeOtpAdmin(contract.id, otp)
      .then(() => {
        setIsProcessing(false);
        enqueueSnackbar("Vous venez de signer le contrat", { variant: "success" });
        onSign();
      })
      .catch(e => {
        setIsProcessing(false);
        console.log(" STH WENT WRONG ", e);
        enqueueSnackbar("Une erreur est survenue lors de la signature du contrat", { variant: "error" });
      });
    setIsProcessing(true);
  }, [contract, enqueueSnackbar, otp, onSign]);

  const handleOtpCodeChange = useCallback(e => {
    setOtp(e.target.value);
  }, []);

  if (otpSent) {
    return (
      <>
        <Typography variant="body1" className={classes.submitText}>
          Afin de signer numériquement ce contrat, vous devez saisir le code qui vous sera envoyé par SMS.
        </Typography>
        <Box className={classes.form}>
          <TextField
            fullWidth
            id="outlined-title"
            label="Code reçu par SMS"
            variant="outlined"
            value={otp}
            onChange={handleOtpCodeChange}
            required
          />
          <Button variant="contained" color="primary" disabled={isProcessing || !otp} onClick={handleChallengeOtp}>
            Valider
          </Button>
          <Button variant="text" disabled={isProcessing} onClick={handleSendOtp}>
            Je n'ai pas reçu de code
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="body1" className={classes.submitText}>
        Afin de signer numériquement ce contrat, vous devez saisir le code qui vous sera envoyé par SMS.
      </Typography>
      <Button variant="outlined" disabled={isProcessing} onClick={handleSendOtp}>
        Recevoir un code
      </Button>
    </>
  );
}
