import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { createTeleporter } from "react-teleporter";

const BreadcrumbsTP = createTeleporter();

export function BreadcrumbsTarget() {
  return <BreadcrumbsTP.Target role="breadcrumbs" />;
}

export function BreadcrumbsSource({ crumbs }) {
  if (!crumbs || crumbs.length <= 1) {
    return null;
  }

  return (
    <BreadcrumbsTP.Source>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <Typography color="textPrimary" key={key}>
              {name}
            </Typography>
          ) : (
            <Link key={key} color="inherit" to={path}>
              {name}
            </Link>
          ),
        )}
      </Breadcrumbs>
    </BreadcrumbsTP.Source>
  );
}
