import React from "react";
import { ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: "#F7F7F7",
  },
  menuItemPicto: {
    color: "#F7F7F7",
  },
  menuItemText: {
    color: "#F7F7F7",
  },
  menuItemSelected: {
    color: theme.palette.primary.main,
  },
  menuItemSelectedPicto: {
    color: theme.palette.primary.main,
  },
  menuItemSelectedText: {
    color: theme.palette.primary.main,
  },
}));

export default function MenuItem({ onClick, isActive, label, Icon }) {
  const classes = useStyles();

  return (
    <ListItem button onClick={onClick} className={isActive ? classes.menuItemSelected : classes.menuItem}>
      <ListItemIcon>
        <Icon className={isActive ? classes.menuItemSelectedPicto : classes.menuItemPicto} />
      </ListItemIcon>
      <ListItemText primary={label} classes={{ root: isActive ? classes.menuItemSelectedText : classes.menuItemText }} />
    </ListItem>
  );
}
