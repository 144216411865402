import React from "react";
import { observer } from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

import UserStore from "../../Stores/UserStore";
import withAuth from "../../HOC/withAuth";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import UserService from "../../Services/UserService";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../../Components/Dialogs/ConfirmationDialog";

function ProfilePage() {
  const user = React.useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = () => {
    UserService.askResetPasswordToken(user.profile.email)
      .then(res => {
        enqueueSnackbar("Un email vous a été envoyé pour réinitialiser votre mot de passe", {
          variant: "success",
        });
        return res;
      })
      .catch(e => {
        enqueueSnackbar("Une erreur est survenue lors de la demande de réinitialisation de votre mot de passe", {
          variant: "error",
        });
        return null;
      });
  };

  return (
    <>
      <PageTitleTPSource>Profil</PageTitleTPSource>
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <Typography variant="h4">
          {user.profile.firstname} {user.profile.lastname.toUpperCase()}
        </Typography>
        <Typography variant="h6">{user.profile.email}</Typography>
        <Button
          style={{ marginTop: 30, marginBottom: 20 }}
          variant="outlined"
          color="primary"
          onClick={handleResetPassword}
        >
          Réinitialiser le mot de passe
        </Button>
        <ConfirmationDialog situationText="Êtes-vous sûr de vouloir vous déconnecter ?">
          {confirmBeforeAct => (
            <Button
              variant="text"
              color="secondary"
              onClick={e =>
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => {
                    user.signOut();
                  }),
                )
              }
            >
              Déconnexion
            </Button>
          )}
        </ConfirmationDialog>
      </Box>
    </>
  );
}

export default withAuth(observer(ProfilePage));
