import React from "react";
import { Box, Button, CircularProgress, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

import ContractTemplatesService from "../../Services/ContractTemplatesService";
import { mockedData } from "../../Services/ContractService";

const useStyles = makeStyles(theme => ({
  form: {
    minWidth: 300,
    width: "50vw",
    maxWidth: 400,
  },
  inputsContainer: {
    padding: theme.spacing(2),
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  resetPassword: {
    marginTop: theme.spacing(2),
  },
}));

function DemoPage() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const _onSubmit = async values => {
    setIsSubmitting(true);
    const data = { ...mockedData };
    if (values.residenceName) {
      data.residence.name = values.residenceName;
    }
    data.residence.pay_to = "SCCV RESIDENCE CAEN 1";
    if (values.clientFirstname) {
      data.client.client_1.firstname = values.clientFirstname;
    }
    if (values.clientLastname) {
      data.client.client_1.lastname = values.clientLastname;
    }
    data.client.pinel_inter = true;
    data.client.pinel = true;
    data.client.client_1.mail = "n.chauris+jack@gmail.com";
    // data.client.client_2 = {
    //   firstname: "Jeanne",
    //   lastname: "MAS",
    //   birthday: "Date de naissance du client 2",
    //   birthplace: "Lieu de naissance du client 2",
    //   phone: "0667725150",
    //   maidenname: "",
    //   mail: "n.chauris+jeanne@gmail.com",
    //   nationality: "Française",
    //   job: "Sans emploi",
    // };
    data.client.client_2 = null;
    data.client.lot.rds_id = values.lot;
    data.residence.rds_id = values.residence;
    data.client.lot.rent = "450,00 €";
    data.client.options.has_rental_vacancy = true;
    data.residence.various_annotations = "";
    data.client.options.lines[0] = {
      label: "PACK INVESTISEUR",
      price_excluding_taxes: "4 167 €",
      price_taxes: "833 €",
      price_including_taxes: "5 000 €",
      free: false,
    };
    data.client.options.lines[1] = {
      label: "Parking supplémentaire",
      price_excluding_taxes: "Prix HT de l'option",
      price_taxes: "Montant TVA de l'option",
      price_including_taxes: "Prix TTC de l'option",
      free: true,
    };
    // data.client.financial_plan.without_loan = true;
    data.client.prices.no_fee_provision_including_taxes = true;
    data.client.prices.additionals_fees = "Total TTC des frais annexes";
    delete data.client.financial_plan.text;

    ContractTemplatesService.createCase({
      user: "Test TEST",
      user_mail: "n.chauris+admin@gmail.com",
      manager_mail: "n.chauris+manager@gmail.com",
      contract: values.contract,
      data: { ...mockedData },
      kapital_customer: false,
    })
      .then(res => {
        window.open(res.data.url);
        setIsSubmitting(false);
      })
      .catch(e => {
        console.log(" FAILED ", e);
        enqueueSnackbar("Une erreur est survenue", { variant: "error" });
        setIsSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Exemple démo</Typography>
      <Box style={{ width: "90%", paddingLeft: 16, paddingRight: 16, height: "80%" }}>
        <Box style={{ marginTop: 24 }}>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="contract"
                      control={control}
                      rules={{ required: { value: true, message: "Champ obligatoire" } }}
                      defaultValue="ACHEVE"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Nom du template*"
                          placeholder="VEFA"
                          variant="outlined"
                          error={Boolean(errors?.contract)}
                          helperText={errors?.contract?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="residence"
                      control={control}
                      rules={{ required: { value: true, message: "Champ obligatoire" } }}
                      defaultValue="93137e39-ee86-4162-8058-97e69c7857bc"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Identifiant de la residence *"
                          placeholder="Identifiant de la residence"
                          variant="outlined"
                          error={Boolean(errors?.residence)}
                          helperText={errors?.residence?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="lot"
                      control={control}
                      rules={{ required: { value: true, message: "Champ obligatoire" } }}
                      defaultValue="c80fa4ce-733f-4230-9be6-7a1b8ddd1337"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Identifiant du lot *"
                          placeholder="Identifiant du lot"
                          variant="outlined"
                          error={Boolean(errors?.lot)}
                          helperText={errors?.lot?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="residenceName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Nom de la résidence"
                          placeholder="Résidence ABC"
                          variant="outlined"
                          error={Boolean(errors?.residenceName)}
                          helperText={errors?.residenceName?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="clientFirstname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Prénom du client 1"
                          placeholder="Jack"
                          variant="outlined"
                          error={Boolean(errors?.clientFirstname)}
                          helperText={errors?.clientFirstname?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="clientLastname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Nom du client 1"
                          placeholder="POTHE"
                          variant="outlined"
                          error={Boolean(errors?.clientLastname)}
                          helperText={errors?.clientLastname?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} align="right" className={classes.buttons}>
                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && <CircularProgress size={18} style={{ marginRight: 8 }} />} Créer
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default DemoPage;
