import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { Tooltip } from "@material-ui/core";

export default function AdminStatus({ user, displaySimpleUser }) {
  if (user?.isSuperAdmin) {
    return (
      <Tooltip title="Superbg">
        <DoneAllIcon />
      </Tooltip>
    );
  }
  if (user?.isAdmin) {
    return (
      <Tooltip title="Administrateur">
        <DoneIcon />
      </Tooltip>
    );
  }
  return null;
}
