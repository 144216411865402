import React from "react";
import { makeStyles, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  select: {
    width: 120,
  },
}));

function ToolbarBlockTypesSelect({ options, value, onChange, config = {} }) {
  const classes = useStyles();

  return (
    <Select id="grouped-native-select" value={value} onChange={onChange} className={classes.select}>
      <MenuItem value="unstyled">Paragraphe</MenuItem>
      <MenuItem value="unordered-list-item">Élément de liste</MenuItem>
      <MenuItem value="header-one">Titre 1</MenuItem>
      <MenuItem value="header-two">Titre 2</MenuItem>
      <MenuItem value="header-three">Titre 3</MenuItem>
      <MenuItem value="header-four">Titre 4</MenuItem>
      <MenuItem value="header-five">Titre 5</MenuItem>
    </Select>
  );
}

export default ToolbarBlockTypesSelect;
