import React, { useCallback } from "react";
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useSnackbar } from "notistack";

import ContractsListItem from "./ContractsListItem";

export default function ContractsList({ contracts, headers }) {
  const { enqueueSnackbar } = useSnackbar();
  const onCopy = useCallback(() => {
    enqueueSnackbar("Le lien de signature du contrat a été copié", { variant: "success" });
  }, [enqueueSnackbar]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell key={header.key}>{header.label}</TableCell>
          ))}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contracts === null ? (
          <TableRow>
            <TableCell colSpan={headers.length + 1} style={{ textAlign: "center" }}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) : (
          contracts?.map(contract => (
            <ContractsListItem key={`contract-${contract.id}`} contract={contract} onCopy={onCopy} headers={headers} />
          ))
        )}
      </TableBody>
    </Table>
  );
}
