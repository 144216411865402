import axiosApiInstance from "../Utils/axios";
import config from "../Utils/config";

const getContractTemplates = () => axiosApiInstance.get("/contractTemplates");

const getContractTemplate = contractTemplateId => axiosApiInstance.get(`/contractTemplates/${contractTemplateId}`);

const deleteContractTemplate = contractTemplateId =>
  axiosApiInstance.delete(`/contractTemplates/${contractTemplateId}`);

const updateContractTemplate = (contractTemplateId, { name, content }) =>
  axiosApiInstance.patch(`/contractTemplates/${contractTemplateId}`, { name, content });

const createContractTemplate = ({ name, content }) => axiosApiInstance.post("/contractTemplates", { name, content });

const createCase = ({ user, user_mail, manager_mail, contract, data, kapital_customer = "false" }) =>
  axiosApiInstance.post(
    "",
    { user, user_mail, manager_mail, contract, data, kapital_customer },
    { headers: { "x-api-key": config.TOKEN } },
  );

const preview = (contractTemplateId, data) =>
  axiosApiInstance.post(`/contractTemplates/${contractTemplateId}/preview`, { data }, { responseType: "blob" });

const _export = {
  getContractTemplates,
  createContractTemplate,
  getContractTemplate,
  updateContractTemplate,
  deleteContractTemplate,
  createCase,
  preview,
};

export default _export;
