import React from "react";
import { WysiwygEditor } from "../Wysiwyg";

export default function ContentBlockEditor({ content, blockType, syncContent }) {
  const handleSave = (state, actions) => {
    // const asHTML = actions.toHTML(state);
    // console.log(" AS ", asHTML);
  };

  return (
    <div className={blockType === "CONTENT_BLOCK_CENTER" ? "center-editor-content" : "left-editor-content"}>
      <WysiwygEditor tmpOnBlur={syncContent} onSave={handleSave} initialContent={content || ""} />
    </div>
  );
}
