import { createContext } from "react";
import { makeAutoObservable } from "mobx";

import TokenService from "../Services/TokenService";

export class User {
  token = "";
  isLogging = false;
  profile = null;

  constructor() {
    makeAutoObservable(this);
  }

  async signIn(profile) {
    this.token = TokenService.getToken();
    this.profile = { ...profile };
  }

  async signOut() {
    this.token = "";

    TokenService.removeToken();
    this.profile = null;
  }

  hasAccess({ requiredFeatures, requiredAcls, aclType = "all" }) {
    if (this.user) {
      return true;
    }
    return false;
  }
}

export default createContext(new User());
