import React from "react";
import { Checkbox, FormControlLabel, makeStyles, Paper, TablePagination } from "@material-ui/core";
import { useSnackbar } from "notistack";

import UsersList from "../../Components/Users/UsersList";
import AdminService from "../../Services/AdminService.js";
import UpdateUserDialog from "../../Components/Dialogs/UpdateUserDialog";

const useStyles = makeStyles(theme => ({
  filter: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

export default function UsersListContainer() {
  const [users, setUsers] = React.useState(null);
  const [total, setTotal] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [focusedUser, setFocusedUser] = React.useState();
  const [showDeleted, setShowDeleted] = React.useState(false);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const fetchUsers = React.useCallback(
    (_page, _showDeleted) => {
      AdminService.getUsers(_page, _showDeleted)
        .then(res => {
          setTotal(res.data.usersCount);
          setUsers(res.data.users);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération des utilisateurs", { variant: "error" });
        });
    },
    [enqueueSnackbar],
  );

  React.useEffect(() => {
    fetchUsers(page, showDeleted);
  }, [fetchUsers, showDeleted, page]);

  const onPageChange = (event, _page) => {
    setPage(_page);
  };

  const toggleShowDeleted = () => setShowDeleted(!showDeleted);

  const deleteUser = user => {
    AdminService.deleteUser(user.id)
      .then(() => {
        fetchUsers();
        enqueueSnackbar("L'utilisateur a été supprimé", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression de l'utilisateur'", { variant: "success" });
      });
  };

  const showUpdateDialogForUser = _user => {
    setFocusedUser(_user);
  };

  const handleClose = updatedUser => {
    if (updatedUser) {
      fetchUsers();
    }
    setFocusedUser(undefined);
  };

  const Pagination = () =>
    users?.length > 0 ? (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={50}
        rowsPerPageOptions={[50]}
        page={page}
        backIconButtonProps={{
          "aria-label": "Page précédente",
        }}
        nextIconButtonProps={{
          "aria-label": "Page suivante",
        }}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
      />
    ) : null;

  return (
    <Paper>
      <div className={classes.filter}>
        <FormControlLabel
          control={<Checkbox checked={showDeleted} onChange={toggleShowDeleted} name="deleted" color="primary" />}
          label="Montrer les utilisateurs supprimés"
        />
      </div>
      <Pagination />
      <UsersList users={users} onDelete={deleteUser} onUpdate={showUpdateDialogForUser} />
      <Pagination />
      <UpdateUserDialog open={!!focusedUser} user={focusedUser} onClose={handleClose} />
    </Paper>
  );
}
