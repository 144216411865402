import { Chip } from "@material-ui/core";

export const blockTypes = {
  CONTENT_BLOCK: {
    key: "CONTENT_BLOCK",
    label: "Contenu",
    color: "#A69E69",
  },
  CONTENT_BLOCK_CENTER: {
    key: "CONTENT_BLOCK_CENTER",
    label: "Contenu centré",
    color: "#A69E69",
  },
  CONTENT_BLOCK_CLIENT: {
    key: "CONTENT_BLOCK_CLIENT",
    label: "Bloc client",
    color: "#A69E69",
  },
  CONTENT_BLOCK_PINEL: {
    key: "CONTENT_BLOCK_PINEL",
    label: "Bloc Pinel",
    color: "#A69E69",
  },
  CONTENT_BLOCK_OPTIONS: {
    key: "CONTENT_BLOCK_OPTIONS",
    label: "Bloc options",
    color: "#A69E69",
  },
  CONTENT_BLOCK_FINANCING: {
    key: "CONTENT_BLOCK_FINANCING",
    label: "Bloc financement",
    color: "#A69E69",
  },
  CONTENT_BLOCK_FINANCING_W_OPTIONS: {
    key: "CONTENT_BLOCK_FINANCING_W_OPTIONS",
    label: "Bloc financement avec options",
    color: "#A69E69",
  },
  CONTENT_BLOCK_FINANCING_TEXT: {
    key: "CONTENT_BLOCK_FINANCING_TEXT",
    label: "Bloc détails financement",
    color: "#A69E69",
  },
  BLOCK_LOGO: {
    key: "BLOCK_LOGO",
    label: "Logo RDS",
    color: "#A69E69",
  },
  BLOCK_PAGE_BREAK: {
    key: "BLOCK_PAGE_BREAK",
    label: "Saut de page",
    color: "#A69E69",
  },
};

export const defaultContract = {
  name: "Nom du template",
  content: [],
};

export const blockTypeToChip = blockType => {
  switch (blockType) {
    case "BLOCK_LOGO":
      return ChipBlockLogo();
    case "CONTENT_BLOCK_CENTER":
      return ChipContentCentered();
    case "CONTENT_BLOCK":
      return ChipContent();
    case "CONTENT_BLOCK_CLIENT":
      return ChipBlockClient();
    case "CONTENT_BLOCK_PINEL":
      return ChipBlockPinel();
    case "CONTENT_BLOCK_OPTIONS":
      return ChipBlockOptions();
    case "CONTENT_BLOCK_FINANCING":
      return ChipBlockFinancing();
    case "CONTENT_BLOCK_FINANCING_W_OPTIONS":
      return ChipBlockFinancingWithOptions();
    case "CONTENT_BLOCK_FINANCING_TEXT":
      return ChipBlockNoFinancing();
    case "BLOCK_PAGE_BREAK":
      return ChipBlockPageBreak();
    default:
      return <Chip label="Inconnu" size="small" />;
  }
};

export const ChipBlockPageBreak = () => (
  <Chip
    label={blockTypes.BLOCK_PAGE_BREAK.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.BLOCK_PAGE_BREAK.color }}
  />
);
export const ChipBlockLogo = () => (
  <Chip
    label={blockTypes.BLOCK_LOGO.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.BLOCK_LOGO.color }}
  />
);
export const ChipContentCentered = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_CENTER.label}
    size="small"
    style={{ backgroundColor: blockTypes.CONTENT_BLOCK_CENTER.color }}
  />
);
export const ChipContent = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK.label}
    size="small"
    style={{ backgroundColor: blockTypes.CONTENT_BLOCK.color }}
  />
);
export const ChipBlockClient = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_CLIENT.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.CONTENT_BLOCK_CLIENT.color }}
  />
);
export const ChipBlockPinel = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_PINEL.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.CONTENT_BLOCK_PINEL.color }}
  />
);

export const ChipBlockOptions = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_OPTIONS.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.CONTENT_BLOCK_OPTIONS.color }}
  />
);

export const ChipBlockFinancing = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_FINANCING.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.CONTENT_BLOCK_FINANCING.color }}
  />
);

export const ChipBlockFinancingWithOptions = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_FINANCING_W_OPTIONS.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.CONTENT_BLOCK_FINANCING_W_OPTIONS.color }}
  />
);

export const ChipBlockNoFinancing = () => (
  <Chip
    label={blockTypes.CONTENT_BLOCK_FINANCING_TEXT.label}
    size="small"
    variant="outlined"
    style={{ borderColor: blockTypes.CONTENT_BLOCK_FINANCING_TEXT.color }}
  />
);
