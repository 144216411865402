import React, { useState } from "react";
import { makeStyles, Box, Typography, Button, Grid, Paper, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import ContractService from "../../Services/ContractService";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../../Components/Dialogs/ConfirmationDialog";
import PDFViewer from "../../Components/PDFViewer";

const useStyles = makeStyles(theme => ({
  img: {
    display: "block",
    margin: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  centerz: {
    textAlign: "center",
  },
  iframe: { width: "100%", height: 400, marginTop: theme.spacing(2.5) },
  submitbtn: {
    marginBottom: theme.spacing(2),
  },
  cancelbtn: {
    marginRight: theme.spacing(2),
  },
  loader: {
    height: 300,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& > p": {
      marginTop: theme.spacing(2),
    },
  },
}));

function ConfirmContractPage() {
  const classes = useStyles();
  const [fileSource, setFileSource] = React.useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const { contractId } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setIsProcessing(true);
    ContractService.getContractPdf(contractId, true)
      .then(res => {
        setFileSource(URL.createObjectURL(res.data));
      })
      .catch(e => {
        console.log(" STH WENT WRONG ", e);
        if (e?.response?.status) {
          enqueueSnackbar("Ce contrat a déjà été validé", { variant: "info" });
          history.push(`/signature/${contractId}`);
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la récupération du contrat", { variant: "error" });
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [contractId, enqueueSnackbar, history]);

  const handleValidation = () => {
    setIsProcessing(true);
    ContractService.validateContract(contractId)
      .then(() => {
        history.push(`/signature/${contractId}`);
        enqueueSnackbar("Le contrat a bien été validé", {
          variant: "success",
        });
      })
      .catch(e => {
        console.log(" E ", e);
        enqueueSnackbar("Une erreur est survenue lors de la validation du contrat", { variant: "error" });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <Grid container>
      <Grid container>
        <Grid item md={1} lg={2} xl={3} />
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Paper className={`${classes.paper} ${classes.centerz}`}>
            <Box>
              <img src={"/groupe_kapital_logo.png"} alt="Groupe Kapital logo" height={50} className={classes.logo} />
            </Box>
            <Typography variant="h4" align="center">
              Confirmation d'un contrat
            </Typography>
            {/* <iframe src={fileSource} className={classes.iframe} title="Preview" /> */}

            {fileSource ? (
              <PDFViewer pdfPath={fileSource} />
            ) : (
              <Box className={classes.loader}>
                <CircularProgress />
                <Typography variant="caption" align="center" component="p">
                  Récupération des informations
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      {fileSource && (
        <Grid container>
          <Grid item md={1} lg={2} xl={3} />
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Paper className={`${classes.paper} ${classes.centerz}`}>
              <Box className={classes.submitbtn}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.cancelbtn}
                  onClick={() => {
                    window.close();
                  }}
                >
                  Modifier
                </Button>
                <ConfirmationDialog situationText="En validant ce contrat vous ne pourrez plus le modifer ultérieurement">
                  {confirmBeforeAct => (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isProcessing}
                      onClick={e =>
                        stopPropagationForConfirmation(
                          e,
                          confirmBeforeAct(() => handleValidation()),
                        )
                      }
                    >
                      Valider
                    </Button>
                  )}
                </ConfirmationDialog>
              </Box>
              <Typography variant="caption" align="center" component="p">
                Si des informations sont erronnées, effectuez la modification depuis votre application pour générer une
                nouvelle version
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default observer(ConfirmContractPage);
