import {
  CircularProgress,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { formatRelative } from "date-fns";
import { fr } from "date-fns/locale";

import DropdownButton from "../Buttons/DropdownButton";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../Dialogs/ConfirmationDialog";

const useStyles = makeStyles(theme => ({}));

export default function ApiKeysList({ apiKeys, onDeleteItem }) {
  const [selectedApiKey, setSelectedApiKey] = React.useState(null);
  const classes = useStyles();

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Nom</TableCell>
          <TableCell>Token</TableCell>
          <TableCell>Créée le</TableCell>
          <TableCell>Supprimée le</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {apiKeys ? (
          apiKeys.length > 0 ? (
            apiKeys.map(apiKey => (
              <TableRow key={apiKey.id} selected={!!apiKey.id && apiKey.id === selectedApiKey?.id}>
                <TableCell>{apiKey.name}</TableCell>
                <TableCell>{apiKey.key}</TableCell>
                <TableCell>{formatRelative(new Date(apiKey.createAt), new Date(), { locale: fr })}</TableCell>
                <TableCell>
                  {apiKey.deletedAt ? formatRelative(new Date(apiKey.deletedAt), new Date(), { locale: fr }) : "-"}
                </TableCell>
                <TableCell>
                  <DropdownButton onOpen={() => setSelectedApiKey(apiKey)} onClose={() => setSelectedApiKey(null)}>
                    <ConfirmationDialog>
                      {confirmBeforeAct => (
                        <MenuItem
                          onClick={e =>
                            stopPropagationForConfirmation(
                              e,
                              confirmBeforeAct(() => onDeleteItem(apiKey)),
                            )
                          }
                        >
                          Supprimer
                        </MenuItem>
                      )}
                    </ConfirmationDialog>
                  </DropdownButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="4" align="center">
                Aucun résultat
              </TableCell>
            </TableRow>
          )
        ) : (
          <TableRow>
            <TableCell colSpan="4" align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
