import * as React from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdminService from "../../Services/AdminService";
import { useSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  phone: Yup.string(), //.required("Ce champ est obligatoire"),
});

export default function UpdateUserDialog({ user, onClose }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (user) {
      reset({
        phone: user.phone,
      });
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user, reset]);

  const _onSubmit = async values => {
    setIsSubmitting(true);
    await AdminService.patchUser(user.id, values);
    setIsSubmitting(false);
    enqueueSnackbar("Utilisateur modifié avec succès", { variant: "success" });
    onClose(true);
  };

  if (!user) return null;
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Modification de {user.firstname} {user.lastname.toUpperCase()}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <TextField
            // required
            {...register("phone")}
            variant="outlined"
            fullWidth
            label="Téléphone"
            placeholder="0606060606"
            error={Boolean(errors?.phone)}
            helperText={errors?.phone?.message}
            type="phone"
            autoFocus
          />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              marginBottom: 8,
              justifyContent: "flex-end",
            }}
          >
            <Button variant="text" onClick={onClose} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
              Enregistrer
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
