import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import NavigationButton from "../../Components/Buttons/NavigationButton";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import ApiKeysListContainer from "../../Containers/ApiKeys/ApiKeysListContainer";

import UserStore from "../../Stores/UserStore";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
}));

function SAApiKeysPage() {
  const user = useContext(UserStore);

  const classes = useStyles();

  if (user?.profile?.isSuperAdmin) {
    return (
      <>
        <PageTitleTPSource RightAdornment={<NavigationButton title="Ajouter" to="/superbg/cles-api/nouvelle" />}>
          Clés API
        </PageTitleTPSource>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12}>
            <ApiKeysListContainer />
          </Grid>
        </Grid>
      </>
    );
  }
  return <Redirect to="/" />;
}

export default observer(SAApiKeysPage);
