import { Box, CircularProgress, Paper, Typography, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { isAfter } from "date-fns";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";

import withAuth from "../../HOC/withAuth";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import ContractService from "../../Services/ContractService";
import AdminSignature from "../../Components/Contracts/AdminSignature";
import PDFViewer from "../../Components/PDFViewer";

const useStyles = makeStyles(theme => ({
  loader: {
    width: "100%",
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  loaderText: {
    marginTop: theme.spacing(2),
  },
  iframe: { width: "100%", height: 400 },
  paper: {
    padding: theme.spacing(2),
  },
  centerz: {
    textAlign: "center",
  },
  warning: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  alert: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

function ContractValidation() {
  const [fileSource, setFileSource] = useState(null);
  const [contract, setContract] = useState(null);
  const { contractId } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const fetchContract = useCallback(() => {
    ContractService.getContractPdf(contractId)
      .then(res => {
        const _blob = new Blob([res.data], { type: "application/pdf" });
        setFileSource(window.URL.createObjectURL(_blob));
      })
      .catch(e => {
        console.log(" went wrong", e);
        enqueueSnackbar("Une erreur est survenue lors de la récupération du contrat", { variant: "error" });
      });
    ContractService.getContract(contractId)
      .then(res => {
        setContract(res.data);
      })
      .catch(e => {
        console.log(" FAILED ", e);
        enqueueSnackbar("Une erreur est survenue lors du chargement du contrat", { variant: "error" });
      });
  }, [contractId, enqueueSnackbar]);

  useEffect(() => {
    fetchContract();
  }, [fetchContract]);

  const isReady = useMemo(() => contract && fileSource, [contract, fileSource]);

  const contractSignStatus = useMemo(() => {
    if (contract && contract?.status === "WAITING_FINAL_SIGN") {
      if (isAfter(new Date(), new Date(contract?.deadlineSignature))) {
        return "OUTDATED"; // ERROR
      }
      return "OK";
    }
    if (contract && contract?.status === "CLOSED") {
      return "ALREADY_SIGNED";
    }
    return "UNKNOWN"; // ERROR
  }, [contract]);

  return (
    <>
      <PageTitleTPSource>Signature d'un contrat</PageTitleTPSource>
      {isReady ? (
        <Grid container>
          {contractSignStatus === "BEFORE_CLIENT" && (
            <Grid container className={classes.warning}>
              <Grid item md={1} lg={2} xl={3} />
              <Grid item xs={12} md={10} lg={8} xl={6}>
                <Paper>
                  <Alert severity="warning" style={{ textAlign: "center" }}>
                    Ce contrat n'a pas été signé par le client
                  </Alert>
                </Paper>
              </Grid>
            </Grid>
          )}
          {["OK", "BEFORE_CLIENT"].includes(contractSignStatus) ? (
            <>
              <Grid container>
                <Grid item md={1} lg={2} xl={3} />
                <Grid item xs={12} md={10} lg={8} xl={6}>
                  <Paper className={`${classes.paper} ${classes.centerz}`}>
                    <PDFViewer pdfPath={fileSource} />
                    {/* <iframe src={fileSource} className={classes.iframe} title="Preview" /> */}
                  </Paper>
                </Grid>
              </Grid>
              {contractSignStatus === "OK" && (
                <Grid container className={classes.submit}>
                  <Grid item md={1} lg={2} xl={3} />
                  <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Paper className={`${classes.paper} ${classes.centerz}`}>
                      <AdminSignature contract={contract} onSign={fetchContract} />
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Box className={classes.alert}>
              {contractSignStatus === "ALREADY_SIGNED" ? (
                <Paper>
                  <Alert severity="success">Ce contrat est validé</Alert>
                </Paper>
              ) : (
                <Paper>
                  <Alert severity="error">Ce contrat ne peut pas être signé</Alert>
                </Paper>
              )}
            </Box>
          )}

          <Grid container>
            <Grid item md={1} lg={2} xl={3} />
            <Grid item xs={12} md={10} lg={8} xl={6}></Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={1} lg={2} xl={3} />
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <Box className={classes.loader}>
              <CircularProgress />
              <Typography variant="body1" className={classes.loaderText}>
                Chargement des informations du contrat
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withAuth(ContractValidation);
