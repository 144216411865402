import React from "react";
import { Paper, TablePagination } from "@material-ui/core";
import { useSnackbar } from "notistack";

import ContractTemplatesService from "../../Services/ContractTemplatesService";
import ContractTemplatesList from "../../Components/ContractTemplates/ContractTemplatesList";

export default function ContractTemplatesListContainer() {
  const [templates, setTemplates] = React.useState(null);
  const [total, setTotal] = React.useState(null);
  const [page, setPage] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const fetchContracts = React.useCallback(
    (_page, _showDeleted) => {
      ContractTemplatesService.getContractTemplates()
        .then(res => {
          setTotal(res.data.contractTemplatesCount);
          setTemplates(res.data.contractTemplates);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération des utilisateurs", { variant: "error" });
        });
    },
    [enqueueSnackbar],
  );

  React.useEffect(() => {
    fetchContracts(page);
  }, [fetchContracts, page]);

  const onPageChange = (event, _page) => {
    setPage(_page);
  };

  const deleteContract = contract => {
    ContractTemplatesService.deleteContractTemplate(contract.id)
      .then(() => {
        fetchContracts();
        enqueueSnackbar("Le template a été supprimé", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression du template'", { variant: "error" });
      });
  };

  const Pagination = () =>
    templates?.length > 0 ? (
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={50}
        rowsPerPageOptions={[50]}
        page={page}
        backIconButtonProps={{
          "aria-label": "Page précédente",
        }}
        nextIconButtonProps={{
          "aria-label": "Page suivante",
        }}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
      />
    ) : null;

  return (
    <Paper>
      <Pagination />
      <ContractTemplatesList templates={templates} onDelete={deleteContract} />
      <Pagination />
    </Paper>
  );
}
