import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";

import Menu from "./Menu";
import { BreadcrumbsTarget } from "./Breadcrumbs";
import { Link } from "react-router-dom";
import { PageTitleTPTarget } from "./PageTitle";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft: -10,
    [theme.breakpoints.up("sm")]: {
      marginLeft: -20,
    },
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    "&:hover": { cursor: "pointer" },
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleGoProfile = () => {
    history.push("/profil");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="secondary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ flex: 2 }}>
            <Link to="/">
              <img src={"/groupe_kapital_logo.png"} alt="Groupe Kapital logo" height={50} className={classes.logo} />
            </Link>
          </div>
          <IconButton color="inherit" aria-label="access profile" onClick={handleGoProfile} edge="start">
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu open={open} onClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <BreadcrumbsTarget />
        <PageTitleTPTarget />
        {children}
      </main>
    </div>
  );
}
