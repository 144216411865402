import React from "react";
import {
  FormatBoldRounded,
  FormatClearRounded,
  FormatItalicRounded,
  FormatListBulletedRounded,
  FormatUnderlinedRounded,
} from "@material-ui/icons";

import ToolbarButton from "./ToolbarButton";
import { clearFormat, toggleBlockType, toggleBold, toggleItalic, toggleUnderline } from "./utils";
import ToolbarBlockTypesSelect from "./ToolbarBlockTypesSelect";

export default function Toolbar({ setEditorState, editorState, onInsert = null }) {
  const [blockType, setBlockType] = React.useState("");
  const [currentStyle, setCurrentStyle] = React.useState();

  const handleInsert = () => {
    if (onInsert) {
      setTimeout(() => {
        onInsert();
      }, 100);
    }
  };

  React.useEffect(() => {
    if (editorState) {
      const selection = editorState.getSelection();
      const _blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
      if (_blockType !== blockType) {
        setBlockType(_blockType);
      }
      setCurrentStyle(editorState.getCurrentInlineStyle());
    }
  }, [editorState, blockType]);

  const onSelectBlockTypeChange = e => {
    setBlockType(e.target.value);
    toggleBlockType(setEditorState, e.target.value)(e);
    handleInsert();
  };

  return (
    <div className="toolbar-container">
      <ToolbarBlockTypesSelect value={blockType} onChange={onSelectBlockTypeChange} />
      <ToolbarButton onMouseDown={toggleBold(setEditorState)} selected={currentStyle && currentStyle.has("BOLD")}>
        <FormatBoldRounded />
      </ToolbarButton>
      <ToolbarButton onMouseDown={toggleItalic(setEditorState)} selected={currentStyle && currentStyle.has("ITALIC")}>
        <FormatItalicRounded />
      </ToolbarButton>
      <ToolbarButton
        onMouseDown={toggleUnderline(setEditorState)}
        selected={currentStyle && currentStyle.has("UNDERLINE")}
      >
        <FormatUnderlinedRounded />
      </ToolbarButton>
      <ToolbarButton onMouseDown={clearFormat(setEditorState)}>
        <FormatClearRounded />
      </ToolbarButton>
      <ToolbarButton onMouseDown={toggleBlockType(setEditorState, "unordered-list-item")}>
        <FormatListBulletedRounded />
      </ToolbarButton>
    </div>
  );
}
