import React, { useState } from "react";
import { CircularProgress, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import ContractTemplatesListItem from "./ContractTemplatesListItem";
import UserStore from "../../Stores/UserStore";
import PreviewContractTemplateDialog from "../Dialogs/PreviewContractTemplateDialog";

const useStyles = makeStyles(theme => ({}));

export default function ContractTemplatesList({ templates, onDelete }) {
  const classes = useStyles();
  const history = useHistory();
  const user = React.useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();
  const [focusedContractTemplate, setFocusedContractTemplate] = useState();

  const onRowClick = React.useCallback(
    template => {
      history.push(`/templates/modification/${template.id}`);
    },
    [history],
  );

  const isSuperAdmin = React.useMemo(() => user?.profile?.isSuperAdmin, [user]);

  const onCopy = React.useCallback(() => {
    enqueueSnackbar("Identifiant du template copié", { variant: "info" });
  }, [enqueueSnackbar]);

  const onPreview = React.useCallback(contractTemplate => setFocusedContractTemplate(contractTemplate), []);

  return (
    <>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates ? (
            templates.length > 0 ? (
              templates.map(template => (
                <ContractTemplatesListItem
                  onClick={onRowClick}
                  template={template}
                  key={template.id}
                  canDelete={isSuperAdmin}
                  onCopy={onCopy}
                  onDelete={onDelete}
                  onPreview={onPreview}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="2" align="center">
                  Aucun résultat
                </TableCell>
              </TableRow>
            )
          ) : (
            <TableRow>
              <TableCell colSpan="2" align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <PreviewContractTemplateDialog
        handleClose={() => setFocusedContractTemplate(undefined)}
        contractTemplate={focusedContractTemplate}
      />
    </>
  );
}
