import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

function ConfirmationDialog({ children, title, situationText, cancelText, confirmText }, ref) {
  const [open, setOpen] = React.useState(false);
  const [callback, setCallback] = React.useState(null);

  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
    setCallback(null);
  };

  const handleShow = _callback => e => {
    setCallback(() => _callback);
    setOpen(true);
  };

  const confirm = e => {
    e.stopPropagation();
    e.preventDefault();
    callback();
    setOpen(false);
    setCallback(null);
  };

  return (
    <>
      {children(handleShow, ref)}
      <Dialog
        modal="false"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title || "Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {situationText || "Êtes-vous sûr de vouloir effectuer cette action ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelText || "Annuler"}
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            {confirmText || "Oui"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default React.forwardRef(ConfirmationDialog);

export const stopPropagationForConfirmation = (e, action) => {
  e.stopPropagation();
  action();
};
