import { makeStyles } from "@material-ui/core";
import React from "react";
import { compileWithMock } from "../../Services/ContractService";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    width: "100%",
    "& .variable": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      paddingLeft: 2,
      paddingRight: 2,
    },
  },
  contentContainerCentered: {
    width: "100%",
    textAlign: "center",
    "& .variable": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      paddingLeft: 2,
      paddingRight: 2,
    },
  },
}));

export default function PreviewBlockContent({ contractBlock }) {
  const classes = useStyles();
  const { type, content } = contractBlock;
  if (type === "CONTENT_BLOCK") {
    const compiled = compileWithMock(content);
    return (
      <div className={classes.contentContainer}>
        <div dangerouslySetInnerHTML={{ __html: compiled }} />
      </div>
    );
  }
  if (type === "CONTENT_BLOCK_CENTER") {
    const compiled = compileWithMock(content);
    return (
      <div className={classes.contentContainerCentered}>
        <div dangerouslySetInnerHTML={{ __html: compiled }} />
      </div>
    );
  }
  return <div></div>;
}
