import React from "react";
import { Button, Card, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import UserStore from "../Stores/UserStore";
import UserService from "../Services/UserService";
import TokenService from "../Services/TokenService";
import LoginForm from "../Forms/LoginForm";

const useStyles = makeStyles(theme => ({
  form: {
    minWidth: 300,
    width: "50vw",
    maxWidth: 400,
  },
  inputsContainer: {
    padding: theme.spacing(2),
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  resetPassword: {
    marginTop: theme.spacing(2),
  },
}));

function LoginPage() {
  const user = React.useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async ({ email, password }) => {
    const loggedIn = await UserService.login(email, password)
      .then(res => {
        TokenService.setToken(res.data.token);
        return res;
      })
      .catch(e => {
        if (e?.response?.status === 403) {
          enqueueSnackbar("Adresse email ou mot de passe incorrect", {
            variant: "warning",
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la connexion", { variant: "error" });
        }
        return null;
      });
    return loggedIn;
  };

  const postSubmit = () => {
    UserService.getMe()
      .then(res => {
        user.signIn(res.data);
      })
      .catch(e => {
        enqueueSnackbar("Une erreur est survenue lors de la récupération du profil utilisateur", {
          variant: "error",
        });
      });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.inputsContainer}>
        <LoginForm onSubmit={handleSubmit} postSubmit={postSubmit} />
        <Button className={classes.resetPassword} fullWidth component={Link} to="/oubli-mot-de-passe">
          Mot de passe oublié
        </Button>
      </Card>
    </div>
  );
}

export default observer(LoginPage);
