import axiosApiInstance from "../Utils/axios";

const getLogs = date => axiosApiInstance.get(`/super-admin/logs?date=${date}`);

const getUsers = (page = 0, showDeleted = false) =>
  axiosApiInstance.get(`/super-admin/users?page=${page}${showDeleted ? "&deleted=true" : ""}`);

const createUser = data => axiosApiInstance.post(`/super-admin/users`, { ...data });

const deleteUser = userId => axiosApiInstance.delete(`/super-admin/users/${userId}`);

const patchUser = (userId, data) => axiosApiInstance.patch(`/super-admin/users/${userId}`, data);

const getApiKeys = (page = 0, showDeleted = false) =>
  axiosApiInstance.get(`/super-admin/api-keys?page=${page}${showDeleted ? "&deleted=true" : ""}`);

const createApiKey = ({ name }) => axiosApiInstance.post(`/super-admin/api-keys`, { name });

const deleteApiKey = apiKey => axiosApiInstance.delete(`/super-admin/api-keys/${apiKey}`);

const _export = { getLogs, getUsers, getApiKeys, createApiKey, deleteApiKey, createUser, deleteUser, patchUser };

export default _export;
