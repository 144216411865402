import { Grid, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import NavigationButton from "../../Components/Buttons/NavigationButton";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import ContractTemplatesListContainer from "../../Containers/ContractTemplates/ContractTemplatesListContainer";

import UserStore from "../../Stores/UserStore";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
}));

function ContractTemplatesPage() {
  const user = useContext(UserStore);

  const classes = useStyles();

  return (
    <>
      <PageTitleTPSource
        RightAdornment={user?.profile?.isSuperAdmin && <NavigationButton title="Ajouter" to="/templates/nouveau" />}
      >
        Templates de contrat
      </PageTitleTPSource>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <ContractTemplatesListContainer />
        </Grid>
      </Grid>
    </>
  );
}

export default observer(ContractTemplatesPage);
