import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

function NavigationButton({ title, to }) {
  return (
    <Button variant="contained" color="primary" component={Link} to={to}>
      {title}
    </Button>
  );
}

export default React.memo(NavigationButton);
