import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ContractTemplateStore from "../../Stores/ContractTemplateStore";
import { blockTypeToChip } from "../../Utils/contract";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../Dialogs/ConfirmationDialog";
import PreviewBlockContent from "./PreviewBlockContent";

const useStyles = makeStyles(theme => ({
  summary: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 2,
  },
  blockTitle: {
    flex: 2,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  blockTitleText: {
    marginRight: theme.spacing(),
  },
}));

function ContractTemplateBlock({ contractBlock, index, canMoveDown, canMoveUp, onEdit, allowEdition = false }) {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const contractTemplate = React.useContext(ContractTemplateStore);

  const handleMoveUp = e => {
    e.stopPropagation();
    contractTemplate.moveUp(index);
    setExpanded(false);
  };

  const handleMoveDown = e => {
    e.stopPropagation();
    contractTemplate.moveDown(index);
    setExpanded(false);
  };

  const handleDelete = e => {
    e.stopPropagation();
    contractTemplate.delete(index);
  };

  const toggleExpanded = (e, state) => {
    setExpanded(state);
  };

  const handleEdit = e => {
    e.stopPropagation();
    onEdit(index);
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={toggleExpanded}>
      <AccordionSummary
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.summary}>
          <div className={classes.blockTitle}>
            <Typography className={classes.blockTitleText}>
              #{index} {contractBlock.title && `- ${contractBlock.title}`}
            </Typography>
          </div>
          {blockTypeToChip(contractBlock.type)}
          {allowEdition && (
            <>
              <Tooltip title="Descendre le bloc">
                <span>
                  <IconButton aria-label="move-down" onClick={handleMoveDown} disabled={canMoveDown}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Monter le bloc">
                <span>
                  <IconButton aria-label="move-up" onClick={handleMoveUp} disabled={canMoveUp}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Modifier le bloc">
                <span>
                  <IconButton aria-label="move-up" onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <ConfirmationDialog>
                {confirmBeforeAct => (
                  <IconButton
                    aria-label="delete"
                    onClick={e => {
                      stopPropagationForConfirmation(
                        e,
                        confirmBeforeAct(() => {
                          handleDelete(e);
                        }),
                      );
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ConfirmationDialog>
            </>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <PreviewBlockContent contractBlock={contractBlock} />
      </AccordionDetails>
    </Accordion>
  );
}

export default React.memo(ContractTemplateBlock);
