import React, { useCallback, useContext, useMemo } from "react";
import { MenuItem, TableCell, TableRow, Typography } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatRelative, isAfter } from "date-fns";
import { fr } from "date-fns/locale";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";

import DropdownButton from "../Buttons/DropdownButton";
import config from "../../Utils/config";
import ContractService from "../../Services/ContractService";
import { makeStyles } from "@material-ui/styles";
import ContractClient from "./ContractClient";
import UserStore from "../../Stores/UserStore";

const useStyles = makeStyles(theme => ({
  date: {
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
}));

export default function ContractsListItem({ contract, onCopy, headers }) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = useContext(UserStore);

  const handleRedirConfirmContract = useCallback(() => {
    history.push(history.push(`/preview/${contract.id}`));
  }, [contract.id, history]);

  const handleRedirClientSignature = useCallback(() => {
    history.push(history.push(`/signature/${contract.id}`));
  }, [contract.id, history]);

  const handleRedirAdminSignature = useCallback(() => {
    history.push(history.push(`/validation/${contract.id}`));
  }, [contract.id, history]);

  const handleDownload = useCallback(() => {
    ContractService.getContractPdf(contract.id)
      .then(res => {
        const _blob = new Blob([res.data], { type: "application/pdf" });
        fileDownload(_blob, "contrat.pdf");
      })
      .catch(e => {
        console.log(" CANT", e);
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du contrat", { variant: "error" });
      });
  }, [enqueueSnackbar, contract]);

  const handleSyncRds = useCallback(() => {
    ContractService.confirmContract(contract.id)
      .then(res => {
        enqueueSnackbar("Le contrat a été synchronisé avec RDS", { variant: "success" });
      })
      .catch(e => {
        console.log(" CANT", e);
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du contrat", { variant: "error" });
      });
  }, [enqueueSnackbar, contract]);

  const outdated = useMemo(() => isAfter(new Date(), new Date(contract.deadlineSignature)), [contract]);

  const renderCell = useCallback(
    column => {
      const content = JSON.parse(contract.content);
      if (column.key === "ID") {
        const advisor = content?.advisor?.name || content?.advisor;
        return (
          <>
            <Typography variant="body1">{content.residence}</Typography>
            <Typography variant="subtitle1">{content.lot}</Typography>
            <Typography variant="subtitle2">Conseiller : {advisor}</Typography>
          </>
        );
      }
      if (column.key === "CREATE_AT") {
        return (
          <Typography variant="body1" className={classes.date}>
            {formatRelative(new Date(column.extractor(contract)), new Date(), { locale: fr })}
          </Typography>
        );
      }
      if (column.key === "CLIENT") {
        return <ContractClient contract={contract} />;
      }
      if (column.key === "DEADLINE_DATE") {
        const _date = column.extractor(contract);
        return (
          <Typography className={classes.date} variant="body1" style={{ color: outdated ? "#ef5350" : "inherit" }}>
            {formatRelative(new Date(_date), new Date(), { locale: fr })}
          </Typography>
        );
      }
      if (column.key === "CLOSED_DATE") {
        return (
          <Typography variant="body1" className={classes.date}>
            {formatRelative(new Date(column.extractor(contract)), new Date(), { locale: fr })}
          </Typography>
        );
      }
    },
    [contract, outdated, classes],
  );

  const getContractStatus = useCallback(() => {
    ContractService.getContractStatus(contract.id)
      .then(res => console.log(" Statut du contrat ", res))
      .catch(e => {
        console.log(" FAILED ", e);
      });
  }, [contract]);

  const renderMenu = useCallback(() => {
    if (contract.status === "DRAFT") {
      return <MenuItem onClick={handleRedirConfirmContract}>Valider le contrat</MenuItem>;
    }
    if (contract.status === "WAITING_CLIENT_SIGN") {
      return [
        <MenuItem disabled={outdated} onClick={handleRedirClientSignature} key="client-sign">
          Signature client
        </MenuItem>,
        <CopyToClipboard
          disabled={outdated}
          text={`${config.FRONT}/signature/${contract.id}`}
          onCopy={onCopy}
          key="client-sign-link"
        >
          <MenuItem>Copier le lien de signature</MenuItem>
        </CopyToClipboard>,
        user?.profile?.isSuperAdmin && <MenuItem onClick={getContractStatus}>Statut</MenuItem>,
      ];
    }
    if (contract.status === "WAITING_FINAL_SIGN") {
      return [
        <MenuItem disabled={outdated} onClick={handleDownload} key="admin-download-current">
          Télécharger
        </MenuItem>,
        <MenuItem disabled={outdated} onClick={handleRedirAdminSignature} key="admin-sign">
          Signer
        </MenuItem>,
        user?.profile?.isSuperAdmin && <MenuItem onClick={getContractStatus}>Statut</MenuItem>,
      ];
    }
    if (contract.status === "CLOSED") {
      return [
        <MenuItem onClick={handleDownload}>Télécharger</MenuItem>,
        user?.profile?.isSuperAdmin && <MenuItem onClick={handleSyncRds}>Synchroniser</MenuItem>,
        user?.profile?.isSuperAdmin && <MenuItem onClick={getContractStatus}>Statut</MenuItem>,
      ];
    }
  }, [
    onCopy,
    contract,
    outdated,
    handleRedirAdminSignature,
    handleDownload,
    handleRedirClientSignature,
    handleRedirConfirmContract,
    handleSyncRds,
    getContractStatus,
    user,
  ]);

  return (
    <TableRow key={contract.id}>
      {headers.map(header => (
        <TableCell key={header.key}>{renderCell(header)}</TableCell>
      ))}

      <TableCell>
        {contract.status === "WAITING_SIGNS" && outdated ? <div /> : <DropdownButton>{renderMenu()}</DropdownButton>}
      </TableCell>
    </TableRow>
  );
}
