const placeholders = {
  "{{residence.name}}": "Nom de la résidence",
  "{{residence.zip_code}}": "Code postal de la résidence",
  "{{residence.city}}": "Ville de la résidence",
  "{{residence.address}}": "Adresse de la résidence",
  "{{residence.project_presentation}}": "Présentation du projet",
  "{{residence.seller}}": "Dénomination du vendeur",
  "{{residence.pay_to}}": "Dénomination du destinataire des appels de fonds",
  "{{residence.notary.name}}": "Nom et prénom du notaire",
  "{{residence.notary.city}}": "Ville du notaire",
  "{{residence.notary.address}}": "Adresse du notaire",
  "{{residence.notary.zip_code}}": "Code postal du notaire",
  "{{residence.completion_date}}": "Temporalité estimée de livraison",
  "{{residence.various_annotations}}": "Informations particulières",
  "{{client.client_1.firstname}}": "Prénom du client 1",
  "{{client.client_1.lastname}}": "Nom du client 1",
  "{{client.client_1.maidenname}}": "Nom de jeune fille du client 1",
  "{{client.client_1.birthday}}": "Date de naissance du client 1",
  "{{client.client_1.birthplace}}": "Lieu de naissance du client 1",
  "{{client.client_1.nationality}}": "Nationalité du client 1",
  "{{client.client_1.job}}": "Emploi du client 1",
  "{{client.client_1.phone}}": "Téléphone du client 1",
  "{{client.client_1.mail}}": "Adresse email du client 1",
  "{{client.client_2.firstname}}": "Prénom du client 2",
  "{{client.client_2.lastname}}": "Nom du client 2",
  "{{client.client_2.maidenname}}": "Nom de jeune fille du client 2",
  "{{client.client_2.birthday}}": "Date de naissance du client 2",
  "{{client.client_2.birthplace}}": "Lieu de naissance du client 2",
  "{{client.client_2.nationality}}": "Nationalité du client 2",
  "{{client.client_2.job}}": "Emploi du client 2",
  "{{client.client_2.phone}}": "Téléphone du client 2",
  "{{client.client_2.mail}}": "Adresse email du client 2",
  "{{client.address}}": "Adresse du client",
  "{{client.matrimonial}}": "Régime matrimonial",
  "{{client.wedding.date}}": "Date du mariage",
  "{{client.wedding.place}}": "Ville du mariage",
  "{{client.wedding.status}}": "Régime du contrat de mariage",
  "{{client.wedding.contract_date}}": "Date du contrat de mariage",
  "{{client.wedding.notary_name}}": "Nom du notaire référé au mariage",
  "{{client.lot.id}}": "Numéro de lot réservé",
  "{{client.lot.main_rooms}}": "Nombre de pièces principales du lot réservé",
  "{{client.lot.surface}}": "Surface au sol du lot réservé",
  "{{client.lot.floor}}": "Etage du lot réservé",
  "{{client.lot.parking}}": "Identifiant du stationnement du lot réservé",
  "{{client.pinel}}": "Statut impôt Pinel du lot réservé",
  "{{client.pinel_inter}}": "Statut intermédiaire impôt Pinel",
  "{{client.pinel_inter_fee}}": "Frais et commission de l'intermédiaire impôt Pinel",
  "{{client.pinel_inter_fee_plain}}": "Texte relatif à l'explication Pinel",
  "{{client.prices.sale_excluding_taxes}}": "Prix de vente HT en chiffres",
  "{{client.prices.sale_excluding_taxes_plain}}": "Prix de vente HT en lettres",
  "{{client.prices.sale_taxes}}": "TVA du prix de vente en chiffres",
  "{{client.prices.sale_taxes_plain}}": "TVA du prix de vente en lettres",
  "{{client.prices.sale_including_taxes}}": "Prix de vente TTC en chiffres",
  "{{client.prices.sale_including_taxes_plain}}": "Prix de vente TTC en lettres",
  "{{client.prices.fee_total_including_taxes}}": "Total TTC des frais",
  "{{client.prices.fee_provision_including_taxes}}": "Total TTC des frais provision des frais notariés de vente",
  "{{client.prices.fee_guarantee_including_taxes}}": "Anciennement - Total TTC des frais annexes",
  "{{client.prices.additionals_fees}}": "Total TTC des frais annexes",
  "{{client.prices.total_including_taxes}}": "Prix total TTC",
  "{{client.financial_plan.loan_amount}}": "Montant total emprunté",
  "{{client.financial_plan.loan_company}}": "Organisme prêteur",
  "{{client.financial_plan.own_amount}}": "Montant fonds propres",
  "{{client.financial_plan.max_duration}}": "Durée maximum du prêt",
  "{{client.financial_plan.max_rate}}": "Taux d'intérêt annuel maximum du prêt hors assurance",
  "{{client.financial_plan.no_loan_signatory}}": "Nom/Prénom pour la décharge si absence de prêt",
  "{{client.financial_plan.text}}": "Mentions manuscrites du/des client(s)",
  "{{client.security_deposit.amount}}": "Montant du dépôt de garantie",
  "{{client.security_deposit.signatory}}": "Signataire du dépôt de garantie",
  "{{client.expected_sign_date}}": "Date prévisionnelle de signature de l'acte authentique",
  "{{client.sign_date}}": "Date de signature du contrat",
  "{{client.sign_place}}": "Lieu de signature du contrat",
  "{{client.no_loan_delay}}": "Délai non obtention du prêt",
};

let variables = [];
Object.keys(placeholders).forEach(attr => {
  variables = [...variables, { name: placeholders[attr], code: attr }];
});

export default variables;
