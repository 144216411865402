import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import { arrayMoveMutable } from "array-move";

export class ContractTemplate {
  name = "";
  content = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = template => {
    this.name = template.name ?? "";
    this.content = template.content ?? [];
  };

  addBlock = () => {
    // const index = this.content.length + 1;
    const index = this.content.length;
    this.content = [
      ...this.content,
      {
        type: "CONTENT_BLOCK",
        title: `Bloc ${index}`,
        content: "",
      },
    ];
  };

  moveUp = index => {
    if (parseInt(index, 10) > 0) {
      arrayMoveMutable(this.content, index, index - 1);
    }
  };

  moveDown = index => {
    if (index < this.content.length - 1) {
      arrayMoveMutable(this.content, index, index + 1);
    }
  };

  delete = index => {
    this.content.splice(index, 1);
  };

  getBlock = index => this.content[index] || null;

  updateBlock = (index, data) => {
    const current = this.content[index];
    if (current) {
      const { title, content, type } = data;
      this.content[index] = {
        title: title ?? current.title,
        content: content ?? current.content,
        type: type ?? current.type,
      };
    }
  };

  setName = _name => {
    this.name = _name;
  };

  export = () => {
    return JSON.stringify({ name: this.name, content: this.content });
  };
}

export default createContext(new ContractTemplate());
