import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { createTeleporter } from "react-teleporter";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const PageTitleTP = createTeleporter();

export function PageTitleTPTarget() {
  return <PageTitleTP.Target />;
}

export function PageTitleTPSource({ children, RightAdornment }) {
  const classes = useStyles();
  return (
    <PageTitleTP.Source>
      <div className={classes.container}>
        <Typography variant="h4">{children}</Typography>
        {RightAdornment || null}
      </div>
    </PageTitleTP.Source>
  );
}
