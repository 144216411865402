import React from "react";
import { Redirect } from "react-router";

import TokenService from "../Services/TokenService";
import UserStore from "../Stores/UserStore";

function withAuth(AuthComponent) {
  return props => {
    const user = React.useContext(UserStore);
    if (user?.profile && TokenService.getToken()) {
      return <AuthComponent {...props} />;
    }
    user.signOut();
    return <Redirect to="/" />;
  };
}

export default withAuth;
