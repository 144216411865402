import React, { useContext, useEffect } from "react";
import { CircularProgress, makeStyles, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SnackbarProvider } from "notistack";

import "./App.css";
import routes from "./Utils/routes";
import superAdminRoutes from "./Utils/superAdminRoutes";
import theme from "./Styles/theme";
import Layout from "./Components/Layout/Layout";
import { BreadcrumbsSource } from "./Components/Layout/Breadcrumbs";
import UserStore from "./Stores/UserStore";
import LoginPage from "./Pages/LoginPage";
import TokenService from "./Services/TokenService";
import UserService from "./Services/UserService";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import AskResetPasswordPage from "./Pages/AskResetPasswordPage";
import ConfirmContractPage from "./Pages/Common/ConfirmContractPage";
import ClientSignaturePage from "./Pages/Common/ClientSignaturePage";
import DemoPage from "./Pages/Common/DemoPage";

const useStyles = makeStyles(theme => ({
  rootLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
  },
}));

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const user = useContext(UserStore);

  const classes = useStyles();

  useEffect(() => {
    const fetchCurrent = async () => {
      if (TokenService.getToken()) {
        setIsLoading(true);
        UserService.getMe()
          .then(res => {
            user.signIn(res.data);
          })
          .catch(e => {
            console.log(" FAILED ", { e });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    };
    fetchCurrent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      <div className="root-container">
        <SnackbarProvider maxSnack={3}>
          {isLoading ? (
            <div className={classes.rootLoading}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            <BrowserRouter>
              {user.token && user.profile ? (
                <Route
                  render={props => (
                    <Layout {...props}>
                      <Switch>
                        {routes.map(route => (
                          <Route
                            exact
                            path={route.path}
                            key={route.key}
                            layout={Layout}
                            render={props => {
                              const crumbs = routes
                                .filter(({ path }) => props.match.path.includes(path))
                                .map(({ path, ...rest }) => ({
                                  path: Object.keys(props.match.params).length
                                    ? Object.keys(props.match.params).reduce(
                                        (path, param) => path.replace(`:${param}`, props.match.params[param]),
                                        path,
                                      )
                                    : path,
                                  ...rest,
                                }));
                              return (
                                <>
                                  {!route.hideBreadcrumbs && <BreadcrumbsSource crumbs={crumbs} />}
                                  <route.Component {...props} />
                                </>
                              );
                            }}
                          />
                        ))}
                        {user?.profile?.isSuperAdmin &&
                          superAdminRoutes.map(route => (
                            <Route
                              exact
                              path={route.path}
                              key={route.key}
                              layout={Layout}
                              render={props => {
                                return (
                                  <>
                                    <route.Component {...props} />
                                  </>
                                );
                              }}
                            />
                          ))}
                        <PublicRouteWrapper
                          path="/reinitialisation-mot-de-passe/:token"
                          component={ResetPasswordPage}
                        />
                        <Route component={() => <div>NOTFOUND</div>} />
                      </Switch>
                    </Layout>
                  )}
                />
              ) : (
                <Switch>
                  <PublicRouteWrapper path="/reinitialisation-mot-de-passe/:token" component={ResetPasswordPage} />
                  <PublicRouteWrapper path="/oubli-mot-de-passe" component={AskResetPasswordPage} />
                  <PublicRouteWrapper path="/demo" component={DemoPage} />
                  <PublicRouteWrapper path="/preview/:contractId" component={ConfirmContractPage} />
                  <PublicRouteWrapper path="/signature/:contractId" component={ClientSignaturePage} />
                  <PublicRouteWrapper component={LoginPage} />
                </Switch>
              )}
            </BrowserRouter>
          )}
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

function PublicRouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return <Route {...rest} render={props => <Component {...props} />} />;
}
export default observer(App);
