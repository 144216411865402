import * as Handlebars from "handlebars";
import axiosApiInstance from "../Utils/axios";

Handlebars.registerHelper("isfalse", function (value) {
  return value === false;
});

export const mockedData = {
  client: {
    client_1: {
      firstname: "Jack",
      lastname: "POTHE",
      maidenname: "",
      birthday: "Date de naissance du client 1",
      birthplace: "Lieu de naissance du client 1",
      nationality: "Nationalité du client 1",
      job: "Emploi du client 1",
      phone: "0667725150",
      mail: "n.chauris@gmail.com",
    },
    client_2: {
      firstname: "Jeanne",
      lastname: "MASS",
      maidenname: "",
      birthday: "Date de naissance du client 2",
      birthplace: "Lieu de naissance du client 2",
      nationality: "Nationalité du client 2",
      job: "Emploi du client 2",
      phone: "0667725150",
      mail: "n.chauris@gmail.com",
    },
    address: "Adresse du client",
    matrimonial: "Régime matrimonial",
    wedding: {
      date: "Date du mariage",
      place: "Ville du mariage",
      status: "Régime du contrat de mariage",
      contract_date: "Date du contrat de mariage",
      notary_name: "Nom du notaire référé au mariage",
    },
    lot: {
      id: "Numéro de lot réservé",
      main_rooms: "Nombre de pièces principales du lot réservé",
      surface: "Surface au sol du lot réservé",
      floor: "Etage du lot réservé",
      parking: "Identifiant du stationnement du lot réservé",
    },
    pinel: "Statut impôt Pinel du lot réservé",
    pinel_inter: "Statut intermédiaire Pinel",
    pinel_inter_fee: "Frais et commission de l'intermédiaire Pinel",
    pinel_inter_fee_plain: "Texte relatif à l'explication Pinel",
    prices: {
      sale_excluding_taxes: "Prix de vente HT en chiffres",
      sale_excluding_taxes_plain: "Prix de vente HT en lettres",
      sale_taxes: "TVA du prix de vente en chiffres",
      sale_taxes_plain: "TVA du prix de vente en lettres",
      sale_including_taxes: "Prix de vente TTC en chiffres",
      sale_including_taxes_plain: "Prix de vente TTC en lettres",
      fee_total_including_taxes: "Total TTC des frais",
      fee_provision_including_taxes: "Total TTC des frais provision des frais notariés de vente",
      fee_guarantee_including_taxes: "Anciennement - Total TTC des frais annexes",
      additionals_fees: "Total TTC des frais annexes",
      total_including_taxes: "Prix total TTC",
      no_fee_provision_including_taxes: true,
    },
    options: {
      lines: [
        {
          label: "Dénomination de l'option",
          price_excluding_taxes: "Prix HT de l'option",
          price_taxes: "Montant TVA de l'option",
          price_including_taxes: "Prix TTC de l'option",
        },
      ],
      total_price_taxes: "Montant TVA total des options",
      total_price_excluding_taxes: "Total HT des options",
      total_price_including_taxes: "Total TTC des options",
    },
    financial_plan: {
      loan_amount: "Montant total emprunté",
      loan_company: "Organisme prêteur",
      own_amount: "Montant fonds propres",
      max_duration: "Durée maximum du prêt",
      max_rate: "Taux d'intérêt annuel maximum du prêt hors assurance",
      no_loan_signatory: "Nom/Prénom pour la décharge si absence de prêt",
      text: "Mentions manuscrites du/des client(s)",
    },
    security_deposit: {
      amount: "Montant du dépôt de garantie",
      signatory: "Signataire du dépôt de garantie",
    },
    expected_sign_date: "Date prévisionnelle de signature de l'acte authentique",
    sign_date: "Date de signature du contrat",
    sign_place: "Lieu de signature du contrat",
    no_loan_delay: "Délai non obtention du prêt",
  },
  residence: {
    logo: "https://lh5.googleusercontent.com/SU3vIr4I2Vqyqi2AgAQEeUK917c4h4_JI5V83VsCEItiySFWTg2e3OieAViuv_ksc8EFwg8EWLnxFMsf-1C6U5D9VWyY4VHbRZgMwJgrzdbg2gHnzlXSg4VGrAQDeG6KPYf_Wi4",
    name: "Nom de la résidence",
    zip_code: "Code postal de la résidence",
    city: "Ville de la résidence",
    address: "Adresse de la résidence",
    project_presentation: "Présentation du projet",
    various_annotations: "Informations particulières",
    seller: "Dénomination du vendeur",
    pay_to: "Dénomination du destinataire des appels de fonds",
    notary: {
      name: "Nom et prénom du notaire",
      city: "Ville du notaire",
      address: "Adresse du notaire",
      zip_code: "Code postal du notaire",
    },
    completion_date: "Temporalité estimée de livraison",
  },
};

export const compileWithMock = templateName => {
  const compile = Handlebars.compile(templateName, { strict: true });
  const data = { client: mockedData.client, residence: mockedData.residence };
  return compile(data);
};

const getContractPdf = (contractId, raw = false) =>
  axiosApiInstance.get(`/contracts/${contractId}/pdf?state=${raw ? "raw" : ""}`, {
    responseType: "blob",
  });

const validateContract = contractId => axiosApiInstance.post(`/contracts/${contractId}/initSign`);

const getSignatures = contractId => axiosApiInstance.get(`/contracts/${contractId}/signatories`);

const sendOtpToSignatory = (contractId, signatoryId) =>
  axiosApiInstance.get(`/contracts/${contractId}/signatories/${signatoryId}/genOTP`);

const challengeOtp = (contractId, signatoryId, otp) =>
  axiosApiInstance.post(`/contracts/${contractId}/signatories/${signatoryId}/checkOTP`, {
    otp,
  });

const getContracts = ({ page = 0, status = "WAITING_SIGNS" }) =>
  axiosApiInstance.get(`/admin/contracts?page=${page}&status=${status}`);

const getContract = contractId => axiosApiInstance.get(`/admin/contracts/${contractId}`);

const getContractStatus = contractId => axiosApiInstance.get(`/admin/contracts/${contractId}/status`);

const confirmContract = contractId => axiosApiInstance.get(`/admin/contracts/confirmation/${contractId}`);

const sendOtpToSignatoryAdmin = contractId => axiosApiInstance.get(`/admin/contracts/${contractId}/genOTP`);

const challengeOtpAdmin = (contractId, otp) =>
  axiosApiInstance.post(`/admin/contracts/${contractId}/checkOTP`, { otp });

const _export = {
  getContractPdf,
  validateContract,
  getSignatures,
  sendOtpToSignatory,
  challengeOtp,
  getContracts,
  getContract,
  sendOtpToSignatoryAdmin,
  challengeOtpAdmin,
  confirmContract,
  getContractStatus,
};

export default _export;
