import { Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import ContractTemplateStore from "../../Stores/ContractTemplateStore";
import UserStore from "../../Stores/UserStore";
import ContractTemplateBlock from "./ContractTemplateBlock";
import ContractTemplateBlockEditDialog from "./ContractTemplateBlockEditDialog";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));

function TreeViewer({ allowEdition = false, onSubmit }) {
  const user = React.useContext(UserStore);
  const contractTemplate = React.useContext(ContractTemplateStore);
  const [showEditDialogForIndex, setShowEditDialogForIndex] = React.useState(null);
  const [title, setTitle] = React.useState(contractTemplate.name);

  const classes = useStyles();

  const handleClose = () => {
    setShowEditDialogForIndex(null);
  };

  const handleEdit = index => setShowEditDialogForIndex(index);

  const handleAddBlock = () => {
    contractTemplate.addBlock();
    handleEdit(contractTemplate.content.length - 1);
  };

  const handleSave = () => {
    contractTemplate.setName(title);
    onSubmit(contractTemplate.export());
  };

  const handleTitleChange = e => {
    setTitle(e.target.value);
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        {allowEdition ? (
          <TextField
            fullWidth
            id="outlined-title"
            label="Nom du template"
            variant="outlined"
            value={title}
            onChange={handleTitleChange}
            required
            disabled={!user?.profile?.isSuperAdmin}
          />
        ) : (
          <Typography variant="h4">{contractTemplate.name}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {contractTemplate?.content?.length > 0 ? (
          contractTemplate.content.map((block, index) => (
            <ContractTemplateBlock
              key={`item-${index + 1}`}
              contractBlock={block}
              index={index}
              canMoveUp={index <= 0}
              canMoveDown={index >= contractTemplate.content.length - 1}
              onEdit={handleEdit}
              allowEdition={allowEdition}
            />
          ))
        ) : (
          <Typography>Le template de contrat ne possède pas encore de contenu</Typography>
        )}
      </Grid>
      <Grid item xs={12} className={classes.btnContainer}>
        <Button onClick={handleAddBlock} variant="outlined" color="secondary">
          Ajouter un bloc
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.btnContainer}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Enregistrer
        </Button>
      </Grid>
      <ContractTemplateBlockEditDialog onClose={handleClose} selectedIndex={showEditDialogForIndex} />
    </Grid>
  );
}

export default observer(TreeViewer);
