import Cookies from "universal-cookie";

import UserService from "./UserService";

const cookies = new Cookies();

const TOKEN_COOKIE_NAME = "USER_TOKEN";

const getToken = () => {
  return cookies.get(TOKEN_COOKIE_NAME);
};

const refreshToken = async () => {
  const refreshedToken = UserService.refreshToken()
    .then(res => {
      setToken(res.data.token);
      return res.data.token;
    })
    .catch(() => {
      return false;
    });
  return refreshedToken;
};

const removeToken = () => {
  cookies.remove(TOKEN_COOKIE_NAME, { path: "/" });
};

const setToken = token => {
  const _now = new Date();
  const _inADay = new Date(_now.setDate(_now.getDate() + 1));
  cookies.set(TOKEN_COOKIE_NAME, token, { path: "/", expires: _inADay });
};

const _export = {
  getToken,
  refreshToken,
  removeToken,
  setToken,
};

export default _export;
