import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";

import TreeViewer from "../../Components/ContractTemplates/TreeViewer";
import ContractTemplateStore from "../../Stores/ContractTemplateStore";
import { defaultContract } from "../../Utils/contract";
import ContractTemplatesService from "../../Services/ContractTemplatesService";

function ContractTemplate() {
  const [isLoading, setIsLoading] = React.useState(true);
  const contractTemplate = React.useContext(ContractTemplateStore);
  const { contractTemplateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const fetchTemplate = React.useCallback(() => {
    if (contractTemplateId) {
      ContractTemplatesService.getContractTemplate(contractTemplateId)
        .then(res => {
          const { content, name } = res.data.contractTemplate;
          contractTemplate.init({ name, content: JSON.parse(content) });
          setIsLoading(false);
        })
        .catch(e => {
          console.log(" E ", e);
          enqueueSnackbar("Une erreur est survenue lors de la récupération du template", { variant: "error" });
          setIsLoading(false);
        });
    } else {
      contractTemplate.init(defaultContract);
      setIsLoading(false);
    }
  }, [contractTemplateId, contractTemplate, enqueueSnackbar]);

  const handleSubmit = contract => {
    const { name, content } = JSON.parse(contract);
    if (contractTemplateId) {
      ContractTemplatesService.updateContractTemplate(contractTemplateId, { name, content })
        .then(() => {
          enqueueSnackbar("Le template a bien été modifié", { variant: "success" });
          history.push("/templates");
        })
        .catch(e => {
          if (e?.response?.status === 409) {
            enqueueSnackbar("Un template existe déjà avec ce nom", { variant: "warning" });
          } else {
            enqueueSnackbar("Une erreur est survenue lors de la mise à jour du template", { variant: "error" });
          }
        });
    } else {
      ContractTemplatesService.createContractTemplate({ name, content })
        .then(() => {
          enqueueSnackbar("Le template a bien été créé", { variant: "success" });
          history.push("/templates");
        })
        .catch(e => {
          if (e?.response?.status === 409) {
            enqueueSnackbar("Un template existe déjà avec ce nom", { variant: "warning" });
          } else {
            enqueueSnackbar("Une erreur est survenue lors de l'enregistrement du template", { variant: "error" });
          }
        });
    }
  };

  React.useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <TreeViewer allowEdition onSubmit={handleSubmit} />
    </div>
  );
}
export default observer(ContractTemplate);
