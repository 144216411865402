import React from "react";
import { IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function DropdownButton({ onOpen, onClose, children, disabled, size = "medium" }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    if (onOpen && typeof onOpen === "function") onOpen();
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = e => {
    if (onClose && typeof onClose === "function") onClose();
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <>
      <IconButton
        size={size}
        aria-label="Actions"
        aria-owns={anchorEl ? "simple-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
        {children}
      </Menu>
    </>
  );
}
