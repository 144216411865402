import React from "react";
import { Button, Card, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Link, useParams, useHistory } from "react-router-dom";

import ResetPasswordForm from "../Forms/ResetPasswordForm";
import UserService from "../Services/UserService";

const useStyles = makeStyles(theme => ({
  form: {
    minWidth: 300,
    width: "50vw",
    maxWidth: 400,
  },
  inputsContainer: {
    padding: theme.spacing(2),
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  resetPassword: {
    marginTop: theme.spacing(2),
  },
}));

function ResetPasswordPage() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { token } = useParams();
  const history = useHistory();

  const handleSubmit = async ({ password }) => {
    const resetted = await UserService.resetPassword(token, password)
      .then(res => {
        enqueueSnackbar("Votre mot de passe a été modifié.", {
          variant: "success",
        });
        return res;
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la réinitialisation du mot de passe", { variant: "error" });
        return false;
      });
    return resetted;
  };

  const postSubmit = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <Card className={classes.inputsContainer}>
        <ResetPasswordForm onSubmit={handleSubmit} postSubmit={postSubmit} />
        <Button className={classes.resetPassword} fullWidth component={Link} to="/">
          Retour
        </Button>
      </Card>
    </div>
  );
}

export default ResetPasswordPage;
