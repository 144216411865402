import { Box, makeStyles, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "1rem",
    marginLeft: theme.spacing(),
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  nameWithStatusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function ContractClient({ contract }) {
  const classes = useStyles();
  if (contract.status === "DRAFT" || contract.status === "CLOSED" || contract.status === "WAITING_FINAL_SIGN") {
    const content = JSON.parse(contract.content);
    const { client_1, client_2 } = content;
    return (
      <Box className={classes.mainContainer}>
        {client_1 && <Typography variant="body2">{client_1}</Typography>}
        {client_2 && <Typography variant="body2">{client_2}</Typography>}
      </Box>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      {contract.signatories
        .filter(signatory => !signatory.isFinalSign)
        .map(signatory => (
          <Box className={classes.nameWithStatusContainer}>
            <Typography variant="body2">
              {signatory.firstname} {signatory.lastname}
            </Typography>
            {signatory.hasSigned && <CheckCircleIcon className={classes.icon} style={{ color: "#4caf50" }} />}
          </Box>
        ))}
    </Box>
  );
}
