import {
  CircularProgress,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import DropdownButton from "../Buttons/DropdownButton";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../Dialogs/ConfirmationDialog";
import AdminStatus from "./AdminStatus";

const useStyles = makeStyles(theme => ({}));

export default function UsersList({ users, onDelete, onUpdate }) {
  const [selectedUser, setSelectedUser] = React.useState(null);
  const classes = useStyles();

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Prénom / NOM</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Admin</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users ? (
          users.length > 0 ? (
            users.map(user => (
              <TableRow key={user.id} selected={!!user.id && user.id === selectedUser?.id}>
                <TableCell component="th" scope="row">
                  <p>
                    {user.firstname} {user.lastname.toUpperCase()}
                  </p>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <AdminStatus user={user} />
                </TableCell>
                <TableCell>
                  <DropdownButton onOpen={() => setSelectedUser(user)} onClose={() => setSelectedUser(null)}>
                    <MenuItem onClick={() => onUpdate(user)}>Modifier le téléphone</MenuItem>
                    <ConfirmationDialog>
                      {confirmBeforeAct => (
                        <MenuItem
                          onClick={e =>
                            stopPropagationForConfirmation(
                              e,
                              confirmBeforeAct(() => onDelete(user)),
                            )
                          }
                        >
                          Supprimer
                        </MenuItem>
                      )}
                    </ConfirmationDialog>
                  </DropdownButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="5" align="center">
                Aucun résultat
              </TableCell>
            </TableRow>
          )
        ) : (
          <TableRow>
            <TableCell colSpan="5" align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
