import React from "react";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import { makeStyles } from "@material-ui/core";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/mention/lib/plugin.css";

import { defaultSuggestionsFilter, exportConfig, importConfig } from "./utils";
import "./editor.css";
import Toolbar from "./Toolbar";
import variables from "./variables";
import MentionsEntry from "./MentionsEntry";

const useStyles = makeStyles(theme => ({
  editor: {
    height: 150,
    overflowY: "auto",
  },
  suggestionsContainer: {
    height: 200,
    maxHeight: "30vh",
    overflowY: "auto",
    border: "1px solid #F7F7F7",
    borderColor: theme.palette.divider,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    borderRadius: 2,
  },
}));

export default function CustomEditor({ initialContent, onSave, tmpOnBlur }) {
  const [editorState, setEditorState] = React.useState();
  const editor = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState(variables);

  const classes = useStyles();

  const { MentionSuggestions, plugins } = React.useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: {
        mentionSuggestionsEntry: "wysiwyg-suggestion-entry",
        mentionSuggestionsEntryFocused: "wysiwyg-suggestion-entry-focused",
      },
      entityMutability: "IMMUTABLE",
      mentionTrigger: "{{",
      supportWhitespace: true,
      mentionComponent: mentionProps => {
        return (
          <span
            className={mentionProps.className}
            style={{
              backgroundColor: "rgb(166, 158, 105, 0.6)",
              borderRadius: 2,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            {mentionProps.children}
          </span>
        );
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = React.useCallback(_open => {
    setOpen(_open);
  }, []);
  const onSearchChange = React.useCallback(({ value }) => {
    setSuggestions(value ? defaultSuggestionsFilter(value, variables) : variables);
  }, []);

  React.useEffect(() => {
    const _editorState = initialContent
      ? EditorState.createWithContent(convertFromHTML(importConfig)(initialContent))
      : EditorState.createEmpty();
    setEditorState(_editorState);
  }, [initialContent]);

  const focusEditor = () => {
    if (editor.current) {
      editor.current.focus();
    }
  };

  const toHTML = _editorState => {
    return convertToHTML(exportConfig)(_editorState.getCurrentContent());
  };

  // const handleSave = () => {
  //   onSave(editorState, { toHTML });
  // };

  const handleBlur = () => {
    if (tmpOnBlur) {
      tmpOnBlur(editorState, { toHTML });
    }
  };

  if (!editorState) {
    return (
      <div>
        <p>Chargement...</p>
      </div>
    );
  }

  return (
    <div>
      <Toolbar setEditorState={setEditorState} editorState={editorState} onInsert={focusEditor} />
      {/* <button onClick={handleSave}>Export</button> */}
      <div onBlur={handleBlur}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          className={classes.editor}
          plugins={plugins}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
          entryComponent={MentionsEntry}
          popoverContainer={({ children }) => <div className={classes.suggestionsContainer}>{children}</div>}
        />
      </div>
    </div>
  );
}
