import { TablePagination } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import ContractsList from "../../Components/Contracts/ContractsList";

import ContractService from "../../Services/ContractService";

const TABLE_HEADERS = {
  DRAFT: [
    {
      key: "ID",
      label: "Résidence / Lot / Conseiller",
      extractor: value => value.id,
    },
    {
      key: "CLIENT",
      label: "Client(s)",
      extractor: value => value.status,
    },
    {
      key: "CREATE_AT",
      label: "Date de création",
      extractor: value => value.createAt,
    },
  ],
  WAITING_CLIENT_SIGN: [
    {
      key: "ID",
      label: "Résidence / Lot / Conseiller",
      extractor: value => value.id,
    },
    {
      key: "CLIENT",
      label: "Client(s)",
      extractor: value => value.status,
    },
    {
      key: "DEADLINE_DATE",
      label: "Date butoir",
      extractor: value => value.deadlineSignature,
    },
  ],
  WAITING_FINAL_SIGN: [
    {
      key: "ID",
      label: "Résidence / Lot / Conseiller",
      extractor: value => value.id,
    },
    {
      key: "CLIENT",
      label: "Client(s)",
      extractor: value => value.status,
    },
    {
      key: "DEADLINE_DATE",
      label: "Date butoir",
      extractor: value => value.deadlineSignature,
    },
  ],
  CLOSED: [
    {
      key: "ID",
      label: "Résidence / Lot / Conseiller",
      extractor: value => value.id,
    },
    {
      key: "CLIENT",
      label: "Client(s)",
      extractor: value => value.status,
    },
    {
      key: "CLOSED_DATE",
      label: "Validé le",
      extractor: value => value.transactionCloseDate,
    },
  ],
};

export default function ContractsListContainer({ status }) {
  const [contracts, setContracts] = useState(null);
  const [contractsCount, setContractsCount] = useState(null);
  const [page, setPage] = useState(0);

  const fetchContracts = useCallback(() => {
    setContracts(null);
    setContractsCount(null);
    ContractService.getContracts({ page, status }).then(res => {
      setContracts(res?.data?.contracts || []);
      setContractsCount(res?.data?.contractsCount || 0);
    });
  }, [page, status]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  const onPageChange = (event, _page) => {
    setPage(_page);
  };

  return (
    <>
      {contractsCount !== null && (
        <TablePagination
          component="div"
          count={contractsCount}
          rowsPerPage={50}
          rowsPerPageOptions={[50]}
          page={page}
          backIconButtonProps={{
            "aria-label": "Page précédente",
          }}
          nextIconButtonProps={{
            "aria-label": "Page suivante",
          }}
          onPageChange={onPageChange}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
        />
      )}
      <ContractsList contracts={contracts} headers={TABLE_HEADERS[status]} />
      {contractsCount !== null && (
        <TablePagination
          component="div"
          count={contractsCount}
          rowsPerPage={50}
          rowsPerPageOptions={[50]}
          page={page}
          backIconButtonProps={{
            "aria-label": "Page précédente",
          }}
          nextIconButtonProps={{
            "aria-label": "Page suivante",
          }}
          onPageChange={onPageChange}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
        />
      )}
    </>
  );
}
