import axiosApiInstance from "../Utils/axios";

const login = (email, password) => axiosApiInstance.post("/users/login", { email, password });

const refreshToken = () => axiosApiInstance.post("/auth/refresh");

const askResetPasswordToken = email => axiosApiInstance.post("/users/lost-password", { email });

const resetPassword = (token, password) => axiosApiInstance.post(`/users/change-password/${token}`, { password });

const getMe = () => axiosApiInstance.get("/users/me");

const _export = { login, getMe, refreshToken, resetPassword, askResetPasswordToken };

export default _export;
