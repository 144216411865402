import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Redirect, useHistory } from "react-router";
import BugReportRoundedIcon from "@material-ui/icons/BugReportRounded";
import GroupIcon from "@material-ui/icons/Group";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import UserStore from "../../Stores/UserStore";
import { PageTitleTPSource } from "../../Components/Layout/PageTitle";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardIcon: {
    flex: 2,
    color: "#3C3C3C",
  },
}));

function SAHomePage() {
  const user = useContext(UserStore);
  const history = useHistory();

  const classes = useStyles();

  if (user?.profile?.isSuperAdmin) {
    return (
      <>
        <PageTitleTPSource>Accueil BG</PageTitleTPSource>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={6} sm={4} md={3}>
            <Card
              className={classes.card}
              variant="outlined"
              onClick={() => {
                history.push({ pathname: "/superbg/utilisateurs" });
              }}
            >
              <GroupIcon fontSize="large" className={classes.cardIcon} />
              <Typography variant="subtitle1">Utilisateurs</Typography>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Card
              className={classes.card}
              variant="outlined"
              onClick={() => {
                history.push({ pathname: "/superbg/cles-api" });
              }}
            >
              <VpnKeyIcon fontSize="large" className={classes.cardIcon} />
              <Typography variant="subtitle1">Clés API</Typography>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Card
              className={classes.card}
              variant="outlined"
              onClick={() => {
                history.push({ pathname: "/superbg/logs" });
              }}
            >
              <BugReportRoundedIcon fontSize="large" className={classes.cardIcon} />
              <Typography variant="subtitle1">Logs</Typography>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
  return <Redirect to="/" />;
}

export default observer(SAHomePage);
