import React from "react";
import { Button, Card, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import UserService from "../Services/UserService";
import AskResetPasswordForm from "../Forms/AskResetPasswordForm";

const useStyles = makeStyles(theme => ({
  form: {
    minWidth: 300,
    width: "50vw",
    maxWidth: 400,
  },
  inputsContainer: {
    padding: theme.spacing(2),
  },
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  resetPassword: {
    marginTop: theme.spacing(2),
  },
}));

function AskResetPasswordPage() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSubmit = async ({ email }) => {
    const resetted = await UserService.askResetPasswordToken(email)
      .then(res => {
        enqueueSnackbar("Un email vous a été envoyé pour réinitialiser votre mot de passe", {
          variant: "success",
        });
        return res;
      })
      .catch(e => {
        enqueueSnackbar("Une erreur est survenue lors de la demande de réinitialisation de votre mot de passe", {
          variant: "error",
        });
        return null;
      });
    return resetted;
  };

  return (
    <div className={classes.root}>
      <Card className={classes.inputsContainer}>
        <AskResetPasswordForm onSubmit={handleSubmit} />
        <Button className={classes.resetPassword} fullWidth component={Link} to="/">
          Retour
        </Button>
      </Card>
    </div>
  );
}

export default AskResetPasswordPage;
