import React, { useState } from "react";
import { Paper, Tab, Tabs } from "@material-ui/core";

import { PageTitleTPSource } from "../../Components/Layout/PageTitle";
import ContractsListContainer from "../../Containers/Contracts/ContractsListContainer";
import withAuth from "../../HOC/withAuth";

function ContractsPage() {
  const [contractStatus, setContractStatus] = useState("WAITING_FINAL_SIGN");

  const handleContractStatusChange = (event, newValue) => {
    setContractStatus(newValue);
  };

  return (
    <>
      <PageTitleTPSource>Suivi dossiers</PageTitleTPSource>
      <Paper>
        <Tabs
          variant="fullWidth"
          value={contractStatus}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleContractStatusChange}
          aria-label="contract status tabs"
        >
          <Tab label="Brouillon" value="DRAFT" />
          <Tab label="En cours" value="WAITING_CLIENT_SIGN" />
          <Tab label="En attente" value="WAITING_FINAL_SIGN" />
          <Tab label="Signés" value="CLOSED" />
        </Tabs>
        {contractStatus === "DRAFT" && <ContractsListContainer status="DRAFT" />}
        {contractStatus === "WAITING_CLIENT_SIGN" && <ContractsListContainer status="WAITING_CLIENT_SIGN" />}
        {contractStatus === "WAITING_FINAL_SIGN" && <ContractsListContainer status="WAITING_FINAL_SIGN" />}
        {contractStatus === "CLOSED" && <ContractsListContainer status="CLOSED" />}
      </Paper>
    </>
  );
}

export default withAuth(ContractsPage);
