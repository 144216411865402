import axios from "axios";
import config from "./config";
import TokenService from "../Services/TokenService";

const axiosApiInstance = axios.create({ baseURL: config.API });

axiosApiInstance.interceptors.request.use(
  async config => {
    const token = TokenService.getToken();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 419 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await TokenService.refreshToken();
      if (access_token) axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
